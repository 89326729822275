export default {
  lang1: "OK",
  lang2: "Không",
  lang3: "Bạn có muốn tải xuống gói cài đặt mới không?",
  lang4: "Đang tải xuống",
  lang5: "Không tải xuống được gói cập nhật",
  lang6: "Vui lòng mở lại ứng dụng sau khi cài đặt!",
  lang7: "Không cập nhật được tài nguyên",
  lang8: "Hiện tại không có dịch vụ mạng, vui lòng kiểm tra kết nối mạng!",
  lang9: "Nhấn lại để thoát khỏi chương trình",
  lang10: "Cập nhật phiên bản ỨNG DỤNG",
  lang11: "1. Tối ưu hóa trải nghiệm người dùng; 2. Sửa một số sự cố đã biết.",
  lang12: "Cập nhật ngay",
  lang13: "Không tải xuống được gói cài đặt!"
};