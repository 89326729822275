export default {
  "lang1": "Página inicial",
  "lang2": "Assinatura da nova moeda IEO",
  "lang3": "A moeda da assinatura será liberada diariamente",
  "lang4": "Resgata agora",
  "lang5": "Não aberto",
  "lang6": "Fechado",
  "lang7": "Preço unitário",
  "lang8": "hora de início",
  "lang9": "Total da emissão",
  "lang10": "Hora do fim",
  "lang11": "Total restante",
  "lang12": "Ciclo de bloqueio",
  "lang13": "dia",
  "lang14": "Comprar {coin}",
  "lang15": "moeda de pagamento",
  "lang15a": "pagamento",
  "lang16": "equilíbrio",
  "lang17": "Usar Todos",
  "lang18": "Indique por favor a quantidade de compra",
  "lang19": "Pagamento previsto:",
  "lang20": "dar:",
  "lang21": "Registo de encomendas",
  "lang22": "Libertação cumulativa",
  "lang23": "Reconhecimento IEO",
  "lang24": "Promoção do retorno das comissões",
  "lang25": "Introduza a quantidade de compra correcta",
  "lang26": "Total insuficiente restante",
  "lang27": "Tem a certeza de trocar {number} {coin}?",
  "lang28": "Aviso",
  "lang29": "Sem anúncio",
  "lang30": "Detalhes do anúncio",
  "lang31": "Indique a pesquisa de moeda",
  "lang32": "Mineração todos os dias",
  "lang33": "Quanto maior a Força, mais mineração",
  "lang34": "Lista de ofertas",
  "lang35": "Lista crescente",
  "lang36": "Lista de declinações",
  "lang37": "Actualidades",
  "lang38": "contrato",
  "lang39": "fast contrato",
  "lang40": "Facultativo",
  "lang41": "inteiro",
  "lang42": "Placa-mãe",
  "lang43": "GEM",
  "lang44": "Volume de negócios",
  "lang45": "Preço mais recente",
  "lang46": "Aumento de 24H",
  "lang47": "Bloquear a mineração",
  "lang48": "Fundos de custódia",
  "lang49": "Resultados estimados de hoje",
  "lang50": "Rendimentos acumulados",
  "lang51": "Ordem de Custódia",
  "lang52": "Rendimento diário",
  "lang53": "dia",
  "lang54": "Confirmar a Ordem",
  "lang55": "Recente (rendimento diário)",
  "lang56": "Período de custódia",
  "lang57": "Limite único",
  "lang58": "Tempo de pagamento dos dividendos",
  "lang59": "todos os dias",
  "lang60": "Montante da caução",
  "lang61": "Retorno após expiração",
  "lang62": "chamado",
  "lang63": "Indique o valor da custódia",
  "lang64": "Rendimento estimado",
  "lang65": "Indique por favor o valor de confiança correcto",
  "lang66": "Saldo insuficiente da conta",
  "lang67": "O montante não está dentro do limite único",
  "lang68": "Bloquear o Registo",
  "lang69": "Correndo",
  "lang70": "completo",
  "lang71": "Resgatado",
  "lang72": "Rendimentos acumulados",
  "lang73": "Tempo de custódia",
  "lang74": "Tempo de expiração",
  "lang75": "Confirme a fiduciária de resgate antecipado, e a taxa de serviço correspondente será deduzida para resgate antecipado",
  "lang76": "",
  "lang77": "",
  "lang78": "",
  "lang79": "",
  "lang80": ""
};