import { post, fetch } from '@/service';
import store from '@/store';
var baseURL = '/api/';

var showLoading = function showLoading() {
  store.dispatch('common/showLoading', true);
};

export var getVersion = function getVersion(data) {
  return fetch("".concat(baseURL, "/home/version"), data);
};
export var downloadUrl = function downloadUrl() {
  return fetch("".concat(baseURL, "/home/versions"));
};
export var uploadImage = function uploadImage(data, config) {
  showLoading();
  return post("".concat(baseURL, "/res/upload"), data, config);
};
export var getOssKey = function getOssKey() {
  return fetch("".concat(baseURL, "/home/getOssKey"));
};
export var systemTime = function systemTime() {
  return fetch("".concat(baseURL, "/home/systemTime"));
};
export var selectBanner = function selectBanner(data) {
  return fetch("".concat(baseURL, "/home/banner"), data);
};
export var selectNotices = function selectNotices(data) {
  return fetch("".concat(baseURL, "/home/articles"), data);
};
export var selectAreaCode = function selectAreaCode() {
  return fetch("".concat(baseURL, "/home/areaCode"));
};
export var selectAboutUs = function selectAboutUs() {
  return fetch("".concat(baseURL, "/home/aboutUs"));
};