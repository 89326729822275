var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main_content block_bg" },
    [
      _c(
        "van-tabs",
        {
          attrs: {
            border: "",
            "line-height": "1",
            "offset-top": _vm.headerHeight,
            sticky: ""
          },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "van-tab",
            { attrs: { title: _vm.$t("tradeLang.lang108") } },
            [
              _vm._l(_vm.entrustRecord, function(item, index) {
                return _c("ol", { key: index }, [
                  _c("li", { staticClass: "record_box" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "span",
                        { class: [item.type == "buy" ? "green_bg" : "red_bg"] },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.type == "buy"
                                  ? _vm.$t("tradeLang.lang23")
                                  : _vm.$t("tradeLang.lang24")
                              ) +
                              "·" +
                              _vm._s(
                                item.style == "limit"
                                  ? _vm.$t("tradeLang.lang109")
                                  : _vm.$t("tradeLang.lang110")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("h3", [
                        _vm._v(
                          _vm._s(item.tradcoin) + "/" + _vm._s(item.maincoin)
                        )
                      ]),
                      _c("label", [
                        _c(
                          "a",
                          {
                            staticClass: "main_color",
                            on: {
                              click: function($event) {
                                return _vm.closeEntrustOrder(item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("tradeLang.lang111")))]
                        )
                      ])
                    ]),
                    _c("section", [
                      _c("label", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("tradeLang.lang112")))
                        ]),
                        _c("em", [_vm._v(_vm._s(item.price))]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("tradeLang.lang113")))
                        ]),
                        _c("em", [_vm._v(_vm._s(item.wdate))])
                      ]),
                      _c("label", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("tradeLang.lang114")))
                        ]),
                        _c("em", [_vm._v(_vm._s(item.number))]),
                        _c("p", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang115")))
                          ]),
                          _c("em", [_vm._v(_vm._s(item.unfilled || 0))])
                        ])
                      ]),
                      _c("label", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("tradeLang.lang116")))
                        ]),
                        _c("em", [
                          _vm._v(
                            _vm._s(
                              _vm._f("mathFloor1")(item.number * item.price, 8)
                            )
                          )
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("tradeLang.lang117")))
                        ]),
                        _c("em", [
                          _vm._v(_vm._s(_vm._f("entrustState")(item.state)))
                        ])
                      ])
                    ])
                  ])
                ])
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.entrustLoad && _vm.entrustRecord.length == 0,
                      expression: "entrustLoad&&entrustRecord.length==0"
                    }
                  ],
                  staticClass: "no_data"
                },
                [
                  _c("p", { staticClass: "icon_no_data" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang5")))])
                ]
              )
            ],
            2
          ),
          _c(
            "van-tab",
            { attrs: { title: _vm.$t("tradeLang.lang134") } },
            [
              _c(
                "van-list",
                {
                  attrs: { finished: _vm.finished },
                  on: { load: _vm.loadMore },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.historyList, function(item, index) {
                  return _c("ol", { key: index }, [
                    _c("li", { staticClass: "record_box" }, [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "span",
                          {
                            class: [item.type == "buy" ? "green_bg" : "red_bg"]
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.type == "buy"
                                    ? _vm.$t("tradeLang.lang23")
                                    : _vm.$t("tradeLang.lang24")
                                ) +
                                "·" +
                                _vm._s(
                                  item.style == "limit"
                                    ? _vm.$t("tradeLang.lang109")
                                    : _vm.$t("tradeLang.lang110")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c("h3", [
                          _vm._v(
                            _vm._s(item.tradcoin) + "/" + _vm._s(item.maincoin)
                          )
                        ]),
                        _c("label")
                      ]),
                      _c("section", [
                        _c("label", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang112")))
                          ]),
                          _c("em", [_vm._v(_vm._s(item.price))]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang113")))
                          ]),
                          _c("em", [_vm._v(_vm._s(item.wdate))])
                        ]),
                        _c("label", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang114")))
                          ]),
                          _c("em", [_vm._v(_vm._s(item.number))])
                        ]),
                        _c("label", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang116")))
                          ]),
                          _c("em", [
                            _vm._v(
                              _vm._s(
                                _vm._f("mathFloor1")(
                                  item.number * item.price,
                                  8
                                )
                              )
                            )
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang117")))
                          ]),
                          _c("em", [
                            _vm._v(_vm._s(_vm._f("entrustState")(item.state)))
                          ])
                        ])
                      ])
                    ])
                  ])
                }),
                0
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isload && _vm.historyList.length == 0,
                      expression: "isload&&historyList.length==0"
                    }
                  ],
                  staticClass: "no_data"
                },
                [
                  _c("p", { staticClass: "icon_no_data" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang5")))])
                ]
              )
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: _vm.$t("tradeLang.lang273") } },
            [
              _c(
                "van-list",
                {
                  attrs: { finished: _vm.finished1 },
                  on: { load: _vm.loadMore1 },
                  model: {
                    value: _vm.loading1,
                    callback: function($$v) {
                      _vm.loading1 = $$v
                    },
                    expression: "loading1"
                  }
                },
                _vm._l(_vm.orderList, function(item, index) {
                  return _c("ol", { key: index }, [
                    _c("li", { staticClass: "record_box" }, [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "span",
                          {
                            class: [item.type == "buy" ? "green_bg" : "red_bg"]
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.type == "buy"
                                    ? _vm.$t("tradeLang.lang23")
                                    : _vm.$t("tradeLang.lang24")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c("h3", [
                          _vm._v(
                            _vm._s(item.tradcoin) + "/" + _vm._s(item.maincoin)
                          )
                        ]),
                        _c("label", [_vm._v(_vm._s(item.wdate))])
                      ]),
                      _c("section", [
                        _c("label", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang112")))
                          ]),
                          _c("em", [_vm._v(_vm._s(item.price))])
                        ]),
                        _c("label", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang114")))
                          ]),
                          _c("em", [_vm._v(_vm._s(item.number))])
                        ]),
                        _c("label", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang116")))
                          ]),
                          _c("em", [
                            _vm._v(
                              _vm._s(
                                _vm._f("mathFloor1")(
                                  item.number * item.price,
                                  8
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                }),
                0
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isload1 && _vm.orderList.length == 0,
                      expression: "isload1&&orderList.length==0"
                    }
                  ],
                  staticClass: "no_data"
                },
                [
                  _c("p", { staticClass: "icon_no_data" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang5")))])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }