import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'BonusPopup',
  props: {
    id: {
      type: String,
      required: true
    },
    orderId: {
      type: String,
      required: true
    },
    number: {
      type: [Number, String],
      required: true
    },
    prizeAmount: {
      type: [Number, String],
      required: true
    }
  },
  methods: {
    handleConfirm: function handleConfirm() {
      this.$emit('confirm');
      this.pushPath('/lotteryOrders');
    },
    handleClose: function handleClose() {
      this.$emit('close');
      this.pushPath('/lotteryOrders');
    }
  }
};