var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mask", on: { click: _vm.handleClose } }),
    _c(
      "div",
      {
        staticClass: "confirm_box",
        style: { top: _vm.top + "px" },
        attrs: { id: _vm.id },
        on: {
          touchstart: _vm.dragStart,
          touchmove: _vm.dragMove,
          touchend: _vm.dragEnd
        }
      },
      [
        _c("div", { staticClass: "header" }, [
          _vm._v(" " + _vm._s(_vm.$t("addLang.lang66")) + " ")
        ]),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "number_grid" },
            _vm._l(29, function(n) {
              return _c(
                "div",
                {
                  key: n - 1,
                  class: [
                    "grid_item",
                    _vm.selectedNumber === n - 1 ? "selected" : ""
                  ],
                  on: {
                    click: function($event) {
                      return _vm.selectNumber(n - 1)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(n - 1) + " ")]
              )
            }),
            0
          ),
          _c("div", { staticClass: "tip_text" }, [
            _vm._v(" " + _vm._s(_vm.$t("addLang.lang67")) + " ")
          ])
        ]),
        _c("div", { staticClass: "confirm_button" }, [
          _c(
            "div",
            { staticClass: "confirm-text", on: { click: _vm.handleConfirm } },
            [
              _vm._v(" " + _vm._s(_vm.$t("addLang.lang66")) + " ( "),
              _c("span", { staticClass: "countdown" }, [
                _vm._v(_vm._s(_vm.countdown) + "s")
              ]),
              _vm._v(" ) ")
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }