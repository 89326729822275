var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my" },
    [
      _c("div", { ref: "myTop", staticClass: "my_top" }, [
        _c("div", { staticClass: "user_header" }),
        _vm.isLogin
          ? _c("ol", { staticClass: "user_box" }, [
              _c("li", [
                _c("div", { staticClass: "user_info" }, [
                  _c("div", { staticClass: "user_vip" }, [
                    _c("img", {
                      attrs: {
                        src: require("@img/" +
                          _vm.$store.state.common.theme +
                          "/vip_icon.png"),
                        alt: ""
                      }
                    })
                  ]),
                  _c("div", { staticClass: "avatar" }, [
                    _c("img", {
                      attrs: {
                        width: "40",
                        height: "40",
                        src: require("@img/" +
                          _vm.$store.state.common.theme +
                          "/default_avatar.png"),
                        alt: ""
                      }
                    })
                  ]),
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "id_vip" }, [
                      _c("span", [
                        _vm._v("ID:" + _vm._s(_vm.userInfo.recomcode || "--"))
                      ]),
                      _c("span", { staticClass: "vip" }, [
                        _vm._v("VIP: Lv." + _vm._s(_vm.userInfo.minlevel))
                      ]),
                      _c(
                        "p",
                        {
                          on: {
                            click: function($event) {
                              return _vm.copyUserId()
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@img/" +
                                _vm.$store.state.common.theme +
                                "/copy_icon.png"),
                              alt: ""
                            }
                          })
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "credit" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("accountLang.lang158")) +
                            ": " +
                            _vm._s(_vm.userInfo.reputation)
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c(
        "ol",
        { staticClass: "my_menu" },
        [
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/idAuth")
                }
              }
            },
            [
              _c("div", { staticClass: "menu_item" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/" +
                        _vm.$store.state.common.theme +
                        "/primary_certification_icon.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang4")))
                ]),
                _c("i", { staticClass: "iconfont icon-arrow-right" })
              ])
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/coinAddress")
                }
              }
            },
            [
              _c("div", { staticClass: "menu_item" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/" +
                        _vm.$store.state.common.theme +
                        "/bind_bankc_card_icon.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang5")))
                ]),
                _c("i", { staticClass: "iconfont icon-arrow-right" })
              ])
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/fundPassword")
                }
              }
            },
            [
              _c("div", { staticClass: "menu_item" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/" +
                        _vm.$store.state.common.theme +
                        "/set_fund_password_icon.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang6")))
                ]),
                _c("i", { staticClass: "iconfont icon-arrow-right" })
              ])
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/loginPassword")
                }
              }
            },
            [
              _c("div", { staticClass: "menu_item" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/" +
                        _vm.$store.state.common.theme +
                        "/set_login_password_icon.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang7")))
                ]),
                _c("i", { staticClass: "iconfont icon-arrow-right" })
              ])
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/helpCenter")
                }
              }
            },
            [
              _c("div", { staticClass: "menu_item" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/" +
                        _vm.$store.state.common.theme +
                        "/helpc_center_icon.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang8")))
                ]),
                _c("i", { staticClass: "iconfont icon-arrow-right" })
              ])
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/lotteryOrders")
                }
              }
            },
            [
              _c("div", { staticClass: "menu_item" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/" +
                        _vm.$store.state.common.theme +
                        "/lottery_orders_icon.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang65")))
                ]),
                _c("i", { staticClass: "iconfont icon-arrow-right" })
              ])
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/myTeam")
                }
              }
            },
            [
              _c("div", { staticClass: "menu_item" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/" +
                        _vm.$store.state.common.theme +
                        "/team_icon.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang9")))
                ]),
                _c("i", { staticClass: "iconfont icon-arrow-right" })
              ])
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/inviteFriends")
                }
              }
            },
            [
              _c("div", { staticClass: "menu_item" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/" +
                        _vm.$store.state.common.theme +
                        "/invitation_icon.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang10")))
                ]),
                _c("i", { staticClass: "iconfont icon-arrow-right" })
              ])
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  _vm.langSheet = true
                }
              }
            },
            [
              _c("div", { staticClass: "menu_item" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/" +
                        _vm.$store.state.common.theme +
                        "/language_icon.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang11")))
                ]),
                _c("i", { staticClass: "iconfont icon-arrow-right" })
              ])
            ]
          ),
          _c("van-action-sheet", {
            attrs: {
              actions: _vm.langList,
              "cancel-text": _vm.$t("commonLang.lang1")
            },
            on: { select: _vm.onSelectLang },
            model: {
              value: _vm.langSheet,
              callback: function($$v) {
                _vm.langSheet = $$v
              },
              expression: "langSheet"
            }
          })
        ],
        1
      ),
      _vm.isLogin
        ? _c(
            "div",
            {
              staticClass: "logout_btn",
              on: {
                click: function($event) {
                  _vm.exitPopup = true
                }
              }
            },
            [
              _c("div", { staticClass: "btn_content" }, [
                _c("img", {
                  attrs: {
                    src: require("@img/" +
                      _vm.$store.state.common.theme +
                      "/logout_icon.png"),
                    alt: ""
                  }
                }),
                _c("span", [_vm._v(_vm._s(_vm.$t("accountLang.lang147")))])
              ])
            ]
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          staticClass: "model_popup",
          attrs: { position: "bottom", "close-on-popstate": "" },
          model: {
            value: _vm.exitPopup,
            callback: function($$v) {
              _vm.exitPopup = $$v
            },
            expression: "exitPopup"
          }
        },
        [
          _c("div", { staticClass: "model_popup_top" }, [
            _c("i", {
              on: {
                click: function($event) {
                  _vm.exitPopup = false
                }
              }
            })
          ]),
          _c("div", { staticClass: "exit_popup_box" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("accountLang.lang147")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("accountLang.lang148")))]),
            _c(
              "div",
              { staticClass: "model_popup_btn flex" },
              [
                _c(
                  "van-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.exitPopup = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("commonLang.lang1")))]
                ),
                _c(
                  "van-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.exit()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("commonLang.lang3")))]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }