import "core-js/modules/es.array.concat";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import tabHeader from '@/components/tabHeader.vue';
import { entrustOrder, entrustHistory, closeEntrust } from '@api/exchange';
export default {
  components: {
    tabHeader: tabHeader
  },
  name: 'CoinRecord',
  data: function data() {
    return {
      active: 0,
      headerArr: [this.$t('tradeLang.lang281'), this.$t('tradeLang.lang282')],
      isload: false,
      loading: false,
      finished: true,
      searchData: {
        page: 1,
        limit: 20
      },
      orderList: []
    };
  },
  props: {
    coinInfo: {
      type: Object,
      required: true
    }
  },
  watch: {
    active: function active(val) {
      if (this.coinInfo.tradcoin) {
        this.getRecord();
      }
    },
    coinInfo: {
      immediate: true,
      handler: function handler(newVal) {
        if (newVal.tradcoin) {
          this.getRecord();
        }
      }
    }
  },
  created: function created() {// console.log(`coinInfo111111`)
    // console.log(`coinInfo: ${JSON.stringify(this.coinInfo)}`)
    // // this.params = JSON.parse(this.$route.params.data)
    // this.getRecord()
  },
  mounted: function mounted() {},
  methods: {
    // 获取委托记录；
    getRecord: function getRecord() {
      var _this = this;

      this.isload = false;
      this.orderList = [];
      this.searchData.page = 1;
      this.searchData = _objectSpread({}, this.searchData, {
        tradcoin: this.coinInfo.tradcoin,
        maincoin: this.coinInfo.maincoin
      });

      if (this.active == 0) {
        entrustOrder(this.searchData).then(function (res) {
          _this.isload = true;
          _this.loading = false;

          if (res.data && res.data.length > 0) {
            _this.orderList = res.data;
            _this.finished = res.count == _this.orderList.length;
          } else {
            _this.orderList = [];
          }
        });
      } else {
        entrustHistory(this.searchData).then(function (res) {
          _this.isload = true;
          _this.loading = false;

          if (res.data && res.data.length > 0) {
            _this.orderList = res.data;
            _this.finished = res.count == _this.orderList.length;
          } else {
            _this.orderList = [];
          }
        });
      }
    },
    loadMore: function loadMore() {
      var _this2 = this;

      this.searchData.page += 1;

      if (this.active == 0) {
        entrustOrder(this.searchData).then(function (res) {
          _this2.isload = true;
          _this2.loading = false;

          if (res.data && res.data.length > 0) {
            _this2.orderList = res.data;
            _this2.finished = res.count == _this2.orderList.length;
          } else {
            _this2.orderList = [];
          }
        });
      } else {
        entrustHistory(this.searchData).then(function (res) {
          _this2.isload = true;
          _this2.loading = false;
          _this2.orderList = _this2.orderList.concat(res.data);
          _this2.finished = res.count == _this2.orderList.length;
        });
      }
    },
    closeEntrustOrder: function closeEntrustOrder(data) {
      var _this3 = this;

      _Dialog.confirm({
        message: this.$t('tradeLang.lang133'),
        confirmButtonText: this.$t('commonLang.lang9'),
        cancelButtonText: this.$t('commonLang.lang1')
      }).then(function () {
        closeEntrust({
          id: data.autoid
        }).then(function (res) {
          _Toast(res.msg);

          _this3.getRecord();
        });
      }).catch(function () {});
    },
    refreshList: function refreshList() {
      // 刷新列表数据
      this.getRecord();
    }
  }
};