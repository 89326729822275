import "core-js/modules/es.object.to-string";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import axios from 'axios';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n'; // 默认超时设置

axios.defaults.timeout = 10000;
axios.defaults.baseURL = ''; // 取消重复请求(即当前正在进行的相同请求)

var pending = {};
var CancelToken = axios.CancelToken;

var removePending = function removePending(key) {
  var isRequest = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (pending[key] && isRequest) {
    pending[key]('Cancel duplicate request');
  }

  delete pending[key];
};

var getRequestIdentify = function getRequestIdentify(config) {
  var isReuest = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var url = config.url;

  if (isReuest) {
    url = config.baseURL + config.url.substring(1, config.url.length);
  }

  return config.method === 'get' ? encodeURIComponent(url + JSON.stringify(config.params)) : encodeURIComponent(config.url + JSON.stringify(config.data));
}; // 记录请求次数


var requestingCount = 0; // http request 拦截器

axios.interceptors.request.use(function (config) {
  requestingCount++; // 拦截重复请求(即当前正在进行的相同请求)

  var requestData = getRequestIdentify(config, true);
  removePending(requestData, true);
  config.cancelToken = new CancelToken(function (c) {
    pending[requestData] = c;
  }); // 设置参数格式

  if (!config.headers['Content-Type']) {
    config.headers = {
      'Content-Type': 'application/json'
    };
  } // 添加token到headers


  var token = localStorage.getItem('token');

  if (token) {
    config.headers.token = token;
  }

  config.headers.locale = i18n.locale; // 鉴权参数设置

  if (config.method === 'get') {
    // get请求下 参数在params中，其他请求在data中
    config.params = config.params || {}; // let json = JSON.parse(JSON.stringify(config.params));
    // 一些参数处理
  } else {
    config.data = config.data || {}; // 一些参数处理
  }

  return config;
}, function (err) {
  return Promise.reject(err);
}); // http response 拦截器

axios.interceptors.response.use(function (response) {
  // 把已经完成的请求从 pending 中移除
  var requestData = getRequestIdentify(response.config);
  removePending(requestData); // 一些统一code的返回处理

  if (response.data.code === 401) {
    // 登录验证
    // 做了个示例跳转项目中登录，并记录下相对路径
    router.push({
      name: 'login'
    });
  }

  requestingCount--;

  if (requestingCount <= 0) {
    setTimeout(function () {
      store.dispatch('common/showLoading', false);
    }, 500);
  }

  return response;
}, function (error) {
  requestingCount--;

  if (requestingCount <= 0) {
    store.dispatch('common/showLoading', false);
  }

  return Promise.reject(error);
});
/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */

export function fetch(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve, reject) {
    axios.get(url, {
      params: params
    }).then(function (response) {
      if (response.data.code === 200) {
        // 返回成功处理  这里传的啥 后续调用的时候 res就是啥
        resolve(response.data); // 我们后台所有数据都是放在返回的data里所以这里统一处理了
      } else {
        // 错误处理
        _Toast(response.data.msg);
      }
    }).catch(function (err) {
      var _err$response, _err$response$data;

      reject(err);
      var message = i18n.t('commonLang.lang12'); // 错误返回

      if (err === null || err === void 0 ? void 0 : (_err$response = err.response) === null || _err$response === void 0 ? void 0 : (_err$response$data = _err$response.data) === null || _err$response$data === void 0 ? void 0 : _err$response$data.message) {
        message = err.response.data.message;
      } // 过滤掉取消重复请求回来的err


      err.response && _Toast(message);
    });
  });
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return new Promise(function (resolve, reject) {
    axios.post(url, data, config).then(function (response) {
      if (response.data.code === 200) {
        resolve(response.data);
      } else if (response.data.code != 401) {
        _Toast(response.data.msg);
      }
    }, function (err) {
      var _err$response2, _err$response2$data;

      reject(err);
      var message = i18n.t('commonLang.lang12');

      if (err === null || err === void 0 ? void 0 : (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : (_err$response2$data = _err$response2.data) === null || _err$response2$data === void 0 ? void 0 : _err$response2$data.message) {
        message = err.response.data.message;
      } // 过滤掉取消重复请求回来的err


      err.response && _Toast(message);
    });
  });
}