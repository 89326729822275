var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "coinListTop", staticClass: "coin_list_top" }, [
      _c("div", { staticClass: "search_box" }, [
        _vm._m(0),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchParams,
              expression: "searchParams"
            }
          ],
          attrs: { type: "text", placeholder: _vm.$t("tradeLang.lang30") },
          domProps: { value: _vm.searchParams },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchParams = $event.target.value
            }
          }
        })
      ])
    ]),
    _c("div", { ref: "searchList", staticClass: "search_list" }, [
      _c(
        "ol",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.areaType != "Favorites",
              expression: "areaType!='Favorites'"
            }
          ]
        },
        [
          _vm._l(_vm.coinList, function(item, index) {
            return item.maincoin == _vm.areaType &&
              item.stringName.indexOf(_vm.searchParams.toLocaleUpperCase()) !=
                -1
              ? _c(
                  "li",
                  {
                    key: index,
                    staticClass: "flex_between",
                    on: {
                      click: function($event) {
                        return _vm.chooseCoin(item)
                      }
                    }
                  },
                  [
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(item.tradcoin))]),
                      _vm._v(" / " + _vm._s(item.maincoin))
                    ]),
                    _c(
                      "span",
                      {
                        class: [
                          item.rise >= 0 ? "green_color" : "red_color",
                          "text_hide"
                        ]
                      },
                      [_vm._v(_vm._s(item.price))]
                    )
                  ]
                )
              : _vm._e()
          }),
          _c("div", { staticClass: "no_data" }, [
            _c("p", { staticClass: "icon_no_data" }),
            _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang4")))])
          ])
        ],
        2
      ),
      _c(
        "ol",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.areaType == "Favorites",
              expression: "areaType=='Favorites'"
            }
          ]
        },
        [
          _vm._l(_vm.coinList, function(item, index) {
            return item.isOptional &&
              item.stringName.indexOf(_vm.searchParams.toLocaleUpperCase()) !=
                -1
              ? _c(
                  "li",
                  {
                    key: index,
                    staticClass: "flex_between",
                    on: {
                      click: function($event) {
                        return _vm.chooseCoin(item)
                      }
                    }
                  },
                  [
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(item.tradcoin))]),
                      _vm._v(" / " + _vm._s(item.maincoin))
                    ]),
                    _c(
                      "span",
                      {
                        class: [
                          item.rise >= 0 ? "green_color" : "red_color",
                          "text_hide"
                        ]
                      },
                      [_vm._v(_vm._s(item.price))]
                    )
                  ]
                )
              : _vm._e()
          }),
          _c("div", { staticClass: "no_data" }, [
            _c("p", { staticClass: "icon_no_data" }),
            _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang4")))])
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("i", { staticClass: "iconfont icon-search" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }