import "core-js/modules/es.object.to-string";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import onlineServiceGif from '@img/common/onlineService.gif';
export default {
  name: 'ServiceButton',
  data: function data() {
    return {
      onlineServiceImg: onlineServiceGif,
      isDragging: false,
      hasMoved: false,
      left: 0,
      top: 0,
      startX: 0,
      startY: 0,
      initialLeft: 0,
      initialTop: 0,
      appOffset: 0
    };
  },
  computed: _objectSpread({}, mapState({
    serviceUrl: function serviceUrl(state) {
      return state.common.serviceUrl;
    }
  }), {
    buttonStyle: function buttonStyle() {
      return {
        left: "".concat(this.left, "px"),
        top: "".concat(this.top, "px")
      };
    }
  }),
  mounted: function mounted() {
    this.fetchServiceUrl();
    this.initPosition();
    document.addEventListener('mousemove', this.onDrag);
    document.addEventListener('mouseup', this.endDrag);
    window.addEventListener('resize', this.initPosition);
  },
  beforeDestroy: function beforeDestroy() {
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.endDrag);
    window.removeEventListener('resize', this.initPosition);
  },
  methods: {
    initPosition: function initPosition() {
      var _this = this;

      this.$nextTick(function () {
        var app = document.getElementById('app');

        if (app) {
          var rect = app.getBoundingClientRect();
          _this.appOffset = rect.left;
          _this.left = _this.appOffset + Math.min(375, rect.width) - 70;
          _this.top = Math.max(50, Math.min(window.innerHeight / 2, window.innerHeight - 50));
        }
      });
    },
    handleClick: function handleClick(e) {
      if (this.isDragging || this.hasMoved) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      this.handleServiceClick();
    },
    startDrag: function startDrag(e) {
      this.isDragging = true;
      this.hasMoved = false;
      var point = e.touches ? e.touches[0] : e;
      this.startX = point.clientX;
      this.startY = point.clientY;
      this.initialLeft = this.left;
      this.initialTop = this.top;
      e.preventDefault();
      e.stopPropagation();
    },
    onDrag: function onDrag(e) {
      if (!this.isDragging) return;
      var point = e.touches ? e.touches[0] : e;
      var deltaX = point.clientX - this.startX;
      var deltaY = point.clientY - this.startY;

      if (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5) {
        this.hasMoved = true;
      }

      var app = document.getElementById('app');
      if (!app) return;
      var rect = app.getBoundingClientRect();
      var newLeft = this.initialLeft + deltaX;
      var newTop = this.initialTop + deltaY;
      newLeft = Math.max(this.appOffset, Math.min(newLeft, this.appOffset + Math.min(375, rect.width) - 50));
      newTop = Math.max(0, Math.min(newTop, window.innerHeight - 50));
      this.left = newLeft;
      this.top = newTop;
      e.preventDefault && e.preventDefault();
      e.stopPropagation && e.stopPropagation();
    },
    endDrag: function endDrag(e) {
      var _this2 = this;

      if (!this.isDragging) return;
      this.isDragging = false;
      var app = document.getElementById('app');
      if (!app) return;
      var rect = app.getBoundingClientRect();
      var appCenter = this.appOffset + Math.min(375, rect.width) / 2;

      if (this.left < appCenter) {
        this.left = this.appOffset + 20;
      } else {
        this.left = this.appOffset + Math.min(375, rect.width) - 70;
      }

      setTimeout(function () {
        _this2.hasMoved = false;
      }, 50);
      e && e.preventDefault();
      e && e.stopPropagation();
    },
    handleServiceClick: function handleServiceClick() {
      if (this.serviceUrl) {
        window.open(this.serviceUrl);
      }
    },
    fetchServiceUrl: function fetchServiceUrl() {
      var _this3 = this;

      try {
        fetch('/api/user/get_type_value?type=KF_ADDRESS_H5', {
          method: "GET",
          headers: {
            "Content-Type": "text/plain;charset=UTF-8",
            token: localStorage.getItem('token') || '',
            locale: localStorage.getItem('lang') || 'EN',
            type: 'KF_ADDRESS_H5'
          }
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          if (data.code == 200 && data.data) {
            _this3.$store.state.common.serviceUrl = data.data;
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};