export default {
  lang1: 'Hủy',
  lang2: 'Gửi',
  lang3: 'Xác nhận',
  lang4: 'Chưa có dữ liệu',
  lang5: 'Chưa có bản ghi',
  lang6: 'Sao chép',
  lang7: 'Sao chép thành công',
  lang8: 'Sao chép không thành công',
  lang9: 'Xác nhận',
  lang10: 'Điểm',
  lang11: 'Tài sản',
  lang12: 'Yêu cầu không thành công! Vui lòng kiểm tra kết nối mạng',
  lang13: 'Chu kỳ',
  lang14: 'Giao dịch',
  lang15: 'Thị trường',
  lang16: 'Liên hệ với Dịch vụ khách hàng!',
  "theme": "Giao diện chủ đề",
  lang18: 'Sao chép thành công',
  lang19: 'Sao chép thất bại'
};