import home from '@/views/home/mainIndex';
import market from '@/views/home/market';
import trade from '@/views/home/trade';
import balances from '@/views/wallet/mainIndex';
import cycle from '@/views/futures/cycle';
import my from '@/views/my/mainIndex';
import order from '@/views/home/order';
export default [{
  path: '/',
  name: 'home',
  component: home,
  meta: {
    level: 1
  }
}, {
  path: '*',
  redirect: '/'
}, {
  path: '/market',
  name: 'market',
  component: market,
  meta: {
    level: 1.1
  }
}, {
  path: '/trade',
  name: 'trade',
  component: trade,
  meta: {
    level: 1.2,
    requiresAuth: true
  }
}, {
  path: '/cycle',
  name: 'cycle',
  component: cycle,
  meta: {
    level: 1.5,
    requiresAuth: true
  }
}, {
  path: '/balances',
  name: 'balances',
  component: balances,
  meta: {
    level: 1.4,
    requiresAuth: true
  }
}, // 新加
{
  path: '/personal',
  name: 'personal',
  component: my,
  meta: {
    level: 0.8
  }
}, // 新加
{
  path: '/order',
  name: 'order',
  component: order,
  meta: {
    level: 1.6,
    requiresAuth: true
  }
}];