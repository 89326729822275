import account from './account';
import home from './home';
import common from './common';
import trade from './trade';
import plus from './plus';
var jp = {
  accountLang: account,
  homeLang: home,
  commonLang: common,
  tradeLang: trade,
  plusLang: plus
};
export default jp;