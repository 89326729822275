//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'NavHeader',
  props: {
    title: {
      type: String,
      default: ''
    },
    leftText: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    leftArrow: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: '#191C24'
    },
    color: {
      type: String,
      default: ''
    }
  },
  mounted: function mounted() {
    this.setBarHeight();
  },
  methods: {
    setBarHeight: function setBarHeight() {
      var el = this.$refs.navHeader; // console.dir(el);

      el.style.paddingTop = "".concat(this.$barHeight, "px");
      var elHeight = this.getStyle(el, 'height');
      this.$store.dispatch('common/setNavHeaderHeight', elHeight);
      el.nextElementSibling && (el.nextElementSibling.style.top = elHeight);
    },
    // 获取元素实际ComputedStyle属性
    getStyle: function getStyle(obj, attr) {
      if (obj.currentStyle) {
        // 兼容IE
        return obj.currentStyle[attr];
      } else {
        return window.getComputedStyle(obj, null)[attr];
      }
    },
    onClickRight: function onClickRight() {
      this.$emit('clickRight');
    }
  }
};