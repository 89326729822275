import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import exchangeList from './components/exchangeList.vue';
import futuresList from './components/futuresList.vue'; // import faitList from "./components/faitList.vue"

import { mapState } from 'vuex';
import { rechargeChannel, getEarning } from '@api/user';
export default {
  components: {
    exchangeList: exchangeList,
    futuresList: futuresList
  },
  data: function data() {
    return {
      active: 0,
      isSticky: false,
      hideAssets: false,
      isHidePanel: false,
      accountEarning: 0,
      todayEarning: 0
    };
  },
  computed: _objectSpread({}, mapState({
    assetsCount: function assetsCount(state) {
      return state.wallet.assetsCount;
    },
    rateData: function rateData(state) {
      return state.wallet.rateData;
    }
  })),
  watch: {},
  beforeDestroy: function beforeDestroy() {
    this.isHidePanel = false;
  },
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 3);
    this.$store.dispatch('wallet/getAssetsCount');
  },
  mounted: function mounted() {
    this.$refs.balancePlaceholder.style.paddingTop = "".concat(this.$barHeight, "px");
    this.getearningInfos();
  },
  methods: {
    scrollEvent: function scrollEvent(e) {
      var scrollTop = e.target.scrollTop;

      if (scrollTop > 20) {
        var opacity = scrollTop / 10 * 0.2;
        this.$refs.balancePlaceholder.style.backgroundColor = "rgb(240,185,10,".concat(opacity, ")");
      } else {
        this.$refs.balancePlaceholder.style.backgroundColor = 'transparent';
      }
    },
    rechargeChannelInfo: function rechargeChannelInfo() {
      var _this = this;

      rechargeChannel().then(function (res) {
        if (res.data == 'SM') {
          _this.pushPath('/coinRecharge');
        } else {
          _Dialog.alert({
            //title: '标题',
            message: _this.$t('commonLang.lang16'),
            confirmButtonText: _this.$t('commonLang.lang9')
          }).then(function () {
            var _window$Tawk_API;

            (_window$Tawk_API = window.Tawk_API) === null || _window$Tawk_API === void 0 ? void 0 : _window$Tawk_API.maximize();
          });
        }
      });
    },
    getearningInfos: function getearningInfos() {
      var _this2 = this;

      getEarning().then(function (res) {
        _this2.accountEarning = res.data.accountEarnings;
        _this2.todayEarning = res.data.todayEarnings;
      });
    }
  }
};