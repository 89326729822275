import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import $ from 'jquery';
export default {
  name: 'ConfirmOrderBox',
  props: {
    id: {
      type: String,
      required: true
    },
    top: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      isdrag: false,
      tempX: '',
      x: '',
      tempY: '',
      y: '',
      selectedNumber: Math.floor(Math.random() * 29),
      countdown: 15,
      timer: null
    };
  },
  mounted: function mounted() {
    this.startCountdown();
  },
  beforeDestroy: function beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    selectNumber: function selectNumber(num) {
      this.selectedNumber = num;
    },
    dragStart: function dragStart(e) {
      this.isdrag = true;
      this.tempX = parseInt($("#".concat(this.id)).css('left') + 0);
      this.tempY = parseInt($("#".concat(this.id)).css('top') + 0);
      this.x = e.touches[0].pageX;
      this.y = e.touches[0].pageY;
    },
    dragMove: function dragMove(e) {
      if (this.isdrag) {
        var curX = this.tempX + e.touches[0].pageX - this.x;
        var curY = this.tempY + e.touches[0].pageY - this.y;
        curX = Math.max(0, Math.min(curX, document.documentElement.clientWidth - $("#".concat(this.id))[0].clientWidth));
        curY = Math.max(0, Math.min(curY, document.documentElement.clientHeight - $("#".concat(this.id))[0].clientHeight));
        $("#".concat(this.id)).css({
          'left': curX,
          'top': curY
        });
        e.preventDefault();
      }
    },
    dragEnd: function dragEnd() {
      this.isdrag = false;
    },
    handleConfirm: function handleConfirm() {
      $("#".concat(this.id)).remove();
      this.$emit('confirm', this.selectedNumber);
    },
    handleClose: function handleClose() {
      //   this.$emit('close')
      $("#".concat(this.id)).remove();
      this.$emit('confirm', this.selectedNumber);
    },
    startCountdown: function startCountdown() {
      var _this = this;

      this.timer = setInterval(function () {
        if (_this.countdown > 0) {
          _this.countdown--;
        } else {
          clearInterval(_this.timer);

          _this.handleConfirm();
        }
      }, 1000);
    }
  }
};