import { post, fetch } from '@/service';
import store from '@/store';
var baseURL = '/api/';

var showLoading = function showLoading() {
  store.dispatch('common/showLoading', true);
};

export var cycleKline = function cycleKline(data) {
  return fetch("".concat(baseURL, "/cycle/kline"), data);
};
export var cycleSymbols = function cycleSymbols() {
  return fetch("".concat(baseURL, "/cycle/symbols"));
};
export var cycleInfo = function cycleInfo(data) {
  return fetch("".concat(baseURL, "/cycle/info"), data);
};
export var cycleBuyMarket = function cycleBuyMarket(data) {
  showLoading();
  return post("".concat(baseURL, "/cycle/buyMarket"), data);
};
export var cycleSellMarket = function cycleSellMarket(data) {
  showLoading();
  return post("".concat(baseURL, "/cycle/sellMarket"), data);
};
export var positionByCoin = function positionByCoin(data) {
  return fetch("".concat(baseURL, "/cycle/positionByCoin"), data);
};
export var historyPosition = function historyPosition(data) {
  return fetch("".concat(baseURL, "/cycle/tradHistory"), data);
};
export var historyPositionByCoin = function historyPositionByCoin(data) {
  return fetch("".concat(baseURL, "/cycle/tradHistoryByCoin"), data);
};