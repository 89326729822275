import "core-js/modules/es.array.concat";
import _toConsumableArray from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { tradEntrust, tradHistory, tradOrder, closeEntrust } from '@api/exchange';
export default {
  name: 'sds',
  data: function data() {
    return {
      active: 0,
      entrustLoad: false,
      entrustRecord: [],
      timeoutObj: null,
      isload: false,
      loading: false,
      finished: true,
      searchData: {
        page: 1,
        limit: 20
      },
      historyList: [],
      isload1: false,
      loading1: false,
      finished1: true,
      searchData1: {
        page: 1,
        limit: 30
      },
      orderList: []
    };
  },
  computed: _objectSpread({}, mapState({
    headerHeight: function headerHeight(state) {
      return state.common.headerHeight;
    }
  })),
  watch: {
    active: function active() {
      this.initData();
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.timeoutObj && clearTimeout(this.timeoutObj);
  },
  created: function created() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      if (this.active == 0) {
        this.getTradEntrust();
      } else if (this.active == 1) {
        this.getTradHistory();
      } else if (this.active == 2) {
        this.getTradOrder();
      }
    },
    getTradEntrust: function getTradEntrust() {
      var _this = this;

      tradEntrust({
        page: 1,
        limit: 100
      }).then(function (res) {
        _this.entrustLoad = true;
        _this.entrustRecord = res.data;
        _this.timeoutObj && clearTimeout(_this.timeoutObj);

        if (_this.entrustRecord.length > 0) {
          _this.timeoutObj = setTimeout(function () {
            _this.getTradEntrust();
          }, 10000);
        }
      });
    },
    closeEntrustOrder: function closeEntrustOrder(data) {
      var _this2 = this;

      _Dialog.confirm({
        message: this.$t('tradeLang.lang133'),
        confirmButtonText: this.$t('commonLang.lang9'),
        cancelButtonText: this.$t('commonLang.lang1')
      }).then(function () {
        closeEntrust({
          id: data.autoid
        }).then(function (res) {
          _Toast(res.msg);

          _this2.getTradEntrust();
        });
      }).catch(function () {});
    },
    getTradHistory: function getTradHistory() {
      this.isload = false;
      this.searchData.page = 0;
      this.loadMore();
    },
    loadMore: function loadMore() {
      var _this3 = this;

      this.searchData.page += 1;
      tradHistory(this.searchData).then(function (res) {
        _this3.isload = true;
        _this3.loading = false;

        if (res.data && res.data.length) {
          _this3.historyList = _this3.searchData.page == 1 ? res.data : [].concat(_toConsumableArray(_this3.historyList), _toConsumableArray(res.data));
        } else {
          _this3.historyList = [];
        }

        _this3.finished = res.count == _this3.historyList.length;
      });
    },
    getTradOrder: function getTradOrder() {
      this.isload1 = false;
      this.searchData1.page = 0;
      this.loadMore1();
    },
    loadMore1: function loadMore1() {
      var _this4 = this;

      this.searchData1.page += 1;
      tradOrder(this.searchData1).then(function (res) {
        _this4.isload1 = true;
        _this4.loading1 = false;

        if (res.data && res.data.length) {
          _this4.orderList = _this4.searchData1.page == 1 ? res.data : [].concat(_toConsumableArray(_this4.orderList), _toConsumableArray(res.data));
        } else {
          _this4.orderList = [];
        }

        _this4.finished1 = res.count == _this4.orderList.length;
      });
    }
  }
};