import "core-js/modules/es.array.filter";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { logout } from '@api/user';
export default {
  data: function data() {
    return {
      userHead: require("@img/".concat(this.$store.state.common.theme, "/logo.png")),
      exitPopup: false,
      tipPopup: false,
      langList: [{
        name: 'English',
        type: 'EN'
      }, {
        name: 'Tiếng Việt',
        type: 'TV'
      }, {
        name: '繁體中文',
        type: 'CN'
      }, {
        name: '日本語',
        type: 'JP'
      }, {
        name: '한국어',
        type: 'KO'
      }, {
        name: 'Deutsch',
        type: 'DE'
      }, {
        name: 'España',
        type: 'ES'
      }, {
        name: 'Français',
        type: 'FR'
      }, {
        name: 'Italia',
        type: 'IT'
      }, {
        name: 'Portugal',
        type: 'PT'
      }, {
        name: 'Indonesia',
        type: 'ID'
      }],
      langSheet: false
    };
  },
  computed: _objectSpread({}, mapState({
    isLogin: function isLogin(state) {
      return state.common.isLogin;
    },
    userInfo: function userInfo(state) {
      return state.user.userInfo;
    },
    // balanceData:state => state.user.balanceData,
    exchangeCoinList: function exchangeCoinList(state) {
      return state.wallet.exchangeCoinList.filter(function (item) {
        return item.coin == 'USDT';
      })[0] || {};
    }
  })),
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 4);
    this.$store.dispatch('user/getUserInfo'); // this.$store.dispatch('user/getMyBalance')

    this.$store.dispatch('wallet/getExchangeAssets');
  },
  mounted: function mounted() {
    this.$refs.myTop.style.paddingTop = "".concat(this.$barHeight, "px");
  },
  methods: {
    exit: function exit() {
      var _this = this;

      logout().then(function (res) {
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');

        _this.$store.dispatch('common/updateLoginStatus', false); //this.pushPath('/login');


        _this.$router.replace('/login');
      });
    },
    onSelectLang: function onSelectLang(data) {
      if (this.$i18n.locale == data.type) {
        this.langSheet = false;
        return;
      }

      localStorage.setItem('locale', data.type);
      window.location.reload();
      this.langSheet = false;
    },
    copyUserId: function copyUserId() {
      var userId = this.userInfo.recomcode || '--'; // 创建临时input元素

      var input = document.createElement('input');
      input.value = userId;
      document.body.appendChild(input);
      input.select();

      try {
        // 执行复制命令
        document.execCommand('copy'); // 提示复制成功

        this.$toast(this.$t('commonLang.lang18')); // 假设这是"复制成功"的国际化文本
      } catch (err) {
        // 复制失败时提示
        this.$toast(this.$t('commonLang.lang19')); // 假设这是"复制失败"的国际化文本
      } finally {
        // 移除临时input元素
        document.body.removeChild(input);
      }
    }
  }
};