var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main_content block_bg" },
    [
      _c(
        "van-tabs",
        {
          attrs: { border: "", "line-height": 1, "line-width": 20 },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "van-tab",
            { attrs: { title: _vm.$t("tradeLang.lang237") } },
            [
              _vm._l(_vm.positionOrder, function(item) {
                return _c(
                  "ol",
                  { key: item.orderid, staticClass: "position_box" },
                  [
                    _c("li", { staticClass: "position_top flex_between" }, [
                      _c("h2", [
                        _vm._v(
                          _vm._s(item.tradcoin) + "/" + _vm._s(item.maincoin)
                        )
                      ]),
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatDate")(
                              Number(item.wdate),
                              "MM-dd hh:mm:ss"
                            )
                          )
                        )
                      ]),
                      item.type == "buy"
                        ? _c("em", { staticClass: "green_color" }, [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang235")))
                          ])
                        : _vm._e(),
                      item.type == "sell"
                        ? _c("em", { staticClass: "red_color" }, [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang236")))
                          ])
                        : _vm._e()
                    ]),
                    _c("li", { staticClass: "position_center flex_between" }, [
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("tradeLang.lang238")) +
                              "(" +
                              _vm._s(item.maincoin) +
                              ")"
                          )
                        ]),
                        _c("p", [_vm._v(_vm._s(item.price || "--"))]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("tradeLang.lang239")))
                        ]),
                        _c(
                          "p",
                          {
                            class: [
                              _vm.coinInfo.rise >= 0
                                ? "green_color"
                                : "red_color"
                            ]
                          },
                          [_vm._v(_vm._s(_vm.coinInfo.price))]
                        )
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("tradeLang.lang240")) +
                              "(" +
                              _vm._s(item.paycoin) +
                              ")"
                          )
                        ]),
                        _c("p", [_vm._v(_vm._s(item.number || "--"))]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("tradeLang.lang241")))
                        ]),
                        item.type == "buy" &&
                        Number(_vm.coinInfo.price) > Number(item.price) &&
                        _vm.gasType == "profit"
                          ? _c("p", { staticClass: "green_color text_hide" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("mathFloor1")(
                                    item.number * item.profitGas,
                                    4
                                  )
                                )
                              )
                            ])
                          : _vm._e(),
                        item.type == "buy" &&
                        Number(_vm.coinInfo.price) <= Number(item.price) &&
                        _vm.gasType == "profit"
                          ? _c("p", { staticClass: "red_color text_hide" }, [
                              _vm._v(
                                "-" +
                                  _vm._s(
                                    _vm._f("mathFloor1")(
                                      item.number * item.lossGas,
                                      4
                                    )
                                  )
                              )
                            ])
                          : _vm._e(),
                        item.type == "sell" &&
                        Number(_vm.coinInfo.price) < Number(item.price) &&
                        _vm.gasType == "profit"
                          ? _c("p", { staticClass: "green_color text_hide" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("mathFloor1")(
                                    item.number * item.profitGas,
                                    4
                                  )
                                )
                              )
                            ])
                          : _vm._e(),
                        item.type == "sell" &&
                        Number(_vm.coinInfo.price) >= Number(item.price) &&
                        _vm.gasType == "profit"
                          ? _c("p", { staticClass: "red_color text_hide" }, [
                              _vm._v(
                                "-" +
                                  _vm._s(
                                    _vm._f("mathFloor1")(
                                      item.number * item.lossGas,
                                      4
                                    )
                                  )
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("tradeLang.lang242")))
                        ]),
                        _c("p", [_vm._v(_vm._s(item.lever) + "s")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("tradeLang.lang243")))
                        ]),
                        _c("p", [_vm._v(_vm._s(item.gas || "--"))])
                      ])
                    ])
                  ]
                )
              }),
              !_vm.positionOrder.length
                ? _c("div", { staticClass: "no_data" }, [
                    _c("p", { staticClass: "icon_no_data" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang5")))])
                  ])
                : _vm._e()
            ],
            2
          ),
          _c(
            "van-tab",
            { attrs: { title: _vm.$t("tradeLang.lang244") } },
            [
              _c(
                "van-list",
                {
                  attrs: { finished: _vm.finished },
                  on: { load: _vm.loadMore },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.historyOrder, function(item) {
                  return _c(
                    "ol",
                    {
                      key: item.autoid,
                      staticClass: "position_box",
                      on: {
                        click: function($event) {
                          return _vm.showDetailsPopup(item)
                        }
                      }
                    },
                    [
                      _c("li", { staticClass: "position_top flex_between" }, [
                        _c("h2", [
                          _vm._v(
                            _vm._s(item.tradcoin) + "/" + _vm._s(item.maincoin)
                          )
                        ]),
                        item.wdate
                          ? _c("i", [_vm._v(_vm._s(item.wdate))])
                          : _vm._e(),
                        item.type == "buy"
                          ? _c("em", { staticClass: "green_color" }, [
                              _vm._v(_vm._s(_vm.$t("tradeLang.lang235")))
                            ])
                          : _vm._e(),
                        item.type == "sell"
                          ? _c("em", { staticClass: "red_color" }, [
                              _vm._v(_vm._s(_vm.$t("tradeLang.lang236")))
                            ])
                          : _vm._e()
                      ]),
                      _c(
                        "li",
                        { staticClass: "position_center flex_between" },
                        [
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("tradeLang.lang238")) +
                                  "(" +
                                  _vm._s(item.maincoin) +
                                  ")"
                              )
                            ]),
                            _c("p", [_vm._v(_vm._s(item.price || "--"))]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("tradeLang.lang245")))
                            ]),
                            _c("p", [_vm._v(_vm._s(item.last_profit || "--"))])
                          ]),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("tradeLang.lang240")) +
                                  "(" +
                                  _vm._s(item.paycoin) +
                                  ")"
                              )
                            ]),
                            _c("p", [_vm._v(_vm._s(item.number || "--"))]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("tradeLang.lang241")))
                            ]),
                            _c(
                              "p",
                              {
                                class: [
                                  item.income >= 0 ? "green_color" : "red_color"
                                ]
                              },
                              [_vm._v(_vm._s(item.income || "--"))]
                            )
                          ]),
                          _c("div", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("tradeLang.lang242")))
                            ]),
                            _c("p", [_vm._v(_vm._s(item.lever) + "s")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("tradeLang.lang243")))
                            ]),
                            _c("p", [_vm._v(_vm._s(item.gas || "--"))])
                          ])
                        ]
                      ),
                      _c("li", { staticClass: "position_bottom" }, [
                        _c("div", { staticClass: "flex" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang246")))
                          ]),
                          _c("p", [_vm._v(_vm._s(item.closedate))])
                        ])
                      ])
                    ]
                  )
                }),
                0
              ),
              !_vm.historyOrder.length && _vm.isload
                ? _c("div", { staticClass: "no_data" }, [
                    _c("p", { staticClass: "icon_no_data" }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang5")))])
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("van-tab", { attrs: { title: _vm.$t("tradeLang.lang247") } }, [
            _c(
              "ol",
              { staticClass: "top_order" },
              [
                _c("li", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang248")))]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang249")))]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("tradeLang.lang250")) +
                        "(" +
                        _vm._s(_vm.coinInfo.maincoin) +
                        ")"
                    )
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang251")))])
                ]),
                _vm._l(_vm.topOrder, function(item) {
                  return _c("li", { key: item.autoid }, [
                    _c("p", [_vm._v(_vm._s(item.wdate))]),
                    item.type == "buy"
                      ? _c("p", { staticClass: "green_color" }, [
                          _vm._v(_vm._s(_vm.$t("tradeLang.lang235")))
                        ])
                      : _vm._e(),
                    item.type == "sell"
                      ? _c("p", { staticClass: "red_color" }, [
                          _vm._v(_vm._s(_vm.$t("tradeLang.lang236")))
                        ])
                      : _vm._e(),
                    _c("p", [_vm._v(_vm._s(item.income))]),
                    _c("p", [_vm._v(_vm._s(item.last_profit))])
                  ])
                })
              ],
              2
            ),
            !_vm.topOrder.length
              ? _c("div", { staticClass: "no_data" }, [
                  _c("p", { staticClass: "icon_no_data" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang5")))])
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }