export default {
  lang1: 'Login',
  lang2: 'You can also use',
  lang3: 'Email',
  lang4: 'Mobile phone',
  lang5: 'Phone',
  lang6: 'Please enter your mobile phone number',
  lang7: 'Email',
  lang8: 'Please enter your email account',
  lang9: 'Password',
  lang9a: 'Please enter the login password',
  lang10: 'Forgot password',
  lang11: 'Register Now',
  lang12: 'Search country/region',
  lang13: 'Commonly used area',
  lang14: 'Email',
  lang15: 'Mobile phone',
  lang16: 'Login password',
  lang17: '6-20 characters, composed of letters and numbers',
  lang18: 'Confirm login password',
  lang19: 'Please enter the password again',
  lang20: 'Code',
  lang21: 'Please enter the verification code',
  lang22: 'Send',
  lang23: 'Invitation code (optional)',
  lang23a: 'Please enter the invitation code',
  lang24: 'I have read and agree',
  lang25: 'User Agreement',
  lang26: 'Privacy Policy',
  lang27: 'Please fill in the correct email address',
  lang28: 'Please fill in the login password consisting of 6-20 digits and letters',
  lang29: 'The two input passwords are inconsistent',
  lang30: 'Android Download',
  lang31: 'iOS download',
  lang32: 'After retrieving the password, the currency cannot be withdrawn within 24 hours',
  lang33: 'Reset password',
  lang34: 'Email',
  lang35: 'Mobile phone',
  lang36: 'Click to login',
  lang37: 'Welcome to BlackRock',
  lang38: 'Welcome,',
  lang39: 'KYC',
  lang40: 'Authenticated',
  lang41: 'Go to authentication',
  lang42: 'Certification review',
  lang43: 'Authentication failed',
  lang44: 'Identity information',
  lang45: 'Name',
  lang46: 'Please enter your name',
  lang47: 'ID Card',
  lang48: 'Please enter the ID number',
  lang49: 'Upload ID picture (front and back)',
  lang50: 'Authentication succeeded',
  lang51: 'Please upload the ID picture',
  lang52: 'Security Center',
  lang53: 'login password',
  lang54: 'Funding Password',
  lang55: 'Modify',
  lang56: 'Settings',
  lang57: 'After changing the password, withdrawals and fiat deposits are prohibited within 24 hours',
  lang58: 'Old password',
  lang59: 'Please enter the old password',
  lang60: 'New password',
  lang61: 'Please enter a new password',
  lang62: 'Confirm Password',
  lang63: 'Please enter the confirmation password',
  lang64: 'The password must be 6-20 numbers and letters',
  lang65: 'Forgot your password?',
  lang66: 'Please fill in the old password consisting of 6-20 digits and letters!',
  lang67: 'Please fill in a new password consisting of 6-20 digits and letters!',
  lang68: 'Email',
  lang69: 'SMS',
  lang70: 'Please enter the fund password',
  lang71: 'Confirm fund password',
  lang72: 'Please fill in the fund password consisting of 6-20 digits and letters!',
  lang73: 'Binding',
  lang74: 'Change mobile phone number',
  lang75: 'Bind mobile phone number',
  lang76: 'Set phone number',
  lang77: 'Please enter the mobile phone number you want to bind and verify the number',
  lang78: 'In order to protect account security, you need to verify your account',
  lang79: 'Send verification code to {tel} for security verification',
  lang80: 'Account security verification',
  lang81: 'Next',
  lang82: 'Change email',
  lang83: 'Bind email',
  lang84: 'Set account binding email',
  lang85: 'Please enter the email address you wish to bind and verify the email address',
  lang86: 'My team',
  lang87: 'Total team members',
  lang88: 'Number of invitees',
  lang89: 'Team List',
  lang90: 'User ID',
  lang91: 'Invitation time',
  lang92: 'time',
  lang93: 'Revenue',
  lang94: 'Revenue details',
  lang95: 'Invite friends',
  lang96: 'Recommend a friend to BlackRock',
  lang97: 'Up to 10% of transaction fee rebate',
  lang98: 'Invite friends to register together',
  lang99: 'Send invitation',
  lang100: 'For friends',
  lang101: 'Friends registration',
  lang102: 'Make a transaction',
  lang103: 'Get corresponding',
  lang104: 'Proportional rebate',
  lang105: 'My code',
  lang106: 'My link',
  lang107: 'View Poster',
  lang108: 'Reward Rules',
  lang109: '1. The user completes the registration by sharing the promotion code or promotion link. Every time a friend generates a real currency transaction, ETF transaction and contract transaction, the sharer can get a corresponding proportion of the handling fee reward. For December 18, 2021 In the future, friends who invite friends who open an account through the sharer can also enjoy a 10% commission reward alone.',
  lang110: "2. Reward ratio: currency, ETF, contract trading reward ratio: The reward ratio is related to the sharer's daily/average CTT Token holdings. The specific rules are as follows:",
  lang111: '(1) CTT Token is less than 500, the reward ratio is 10%',
  lang112: '(2) Hold 500-5000 CTT Token, the reward ratio is 20%',
  lang113: '(3) If you hold more than 5,000 CTT Tokens, the reward ratio is 30%',
  lang114: '(4) BlackRock global partners, please contact the platform staff for higher rewards.',
  lang115: "3. Reward form: The rewards actually obtained by the sharer will be returned in the actual currency used by the friend to settle the transaction fee. For example: when the friend uses ETH to settle the transaction fee, the actual reward obtained by the sharer will be returned in ETH. .When the friend's transaction fee is settled by CTT deduction, the actual rewards obtained by the sharer and the friend will be returned in CTT.",
  lang116: 'Save picture',
  lang117: 'Save the picture successfully',
  lang118: 'Failed to save image',
  lang119: 'Help Center',
  lang120: 'BlackRock Product Team',
  lang121: 'Welcome to communicate with us, every piece of your feedback will be valued',
  lang122: 'Problem details',
  lang123: 'Submit work order',
  lang124: 'Work order title',
  lang125: 'Please enter the title of the ticket',
  lang126: 'Work order content',
  lang127: 'Please describe your problem in detail, it will help us to help you more effectively!',
  lang128: 'Work order list',
  lang129: 'Reply:',
  lang130: 'Processing, please be patient...',
  lang131: 'No work order yet',
  lang132: 'waitting',
  lang133: 'Responded',
  lang134: 'closed',
  lang135: 'The image size cannot exceed 5MB',
  lang136: 'Please enter the content of the work order',
  lang137: 'The number of words in the work order is between 10-200',
  lang138: 'About Us',
  lang139: 'BlackRock trading platform',
  lang140: 'Customer Service Phone',
  lang141: 'Official Twitter',
  lang142: 'Official Telegram',
  lang143: 'Company mailbox',
  lang144: 'Settings',
  lang145: 'Language',
  lang146: 'Version',
  lang147: 'Log out',
  lang148: 'Are you sure you want to log out?',
  lang149: "The world's leading",
  lang150: 'Digital Asset Trading Platform',
  lang151: 'Welcome to download BlackRock',
  lang152: 'Register',
  lang153: 'Multifunctional and democratic crypto exchange. As the first on the market, we give financial benefits to active users. BlackRock is a holistic project, which means implementing solutions on a few business layers regarding the cryptocurrency solutions to make using easier for our users.',
  lang154: 'Our Vision',
  lang155: 'We are sure of the wide application of blockchain technology and the benefits of cryptocurrencies in the near future. By introducing global solutions not only due to technological innovations but also thanks to education, the crypto world ecosystem becomes more friendly for everyone.',
  lang156: 'Download APP',
  lang157: 'White Papers',
  lang158: 'credit',
  lang159: 'Platform Assets',
  lang160: 'Wealth Management Assets',
  lang161: 'Contract Assets',
  lang162: 'Account Balance',
  lang163: 'Deposit',
  lang164: 'Withdraw',
  lang165: 'Transfer',
  lang166: 'Asset Details',
  lang167: 'Welcome to login',
  lang168: 'Account',
  lang169: 'No account yet?',
  lang170: 'Welcome to register',
  lang171: 'Existing account?',
  lang172: 'Login Now',
  lang173: 'By registering, I agree xx',
  lang174: 'Terms of use',
  lang175: 'and',
  lang176: 'Privacy treaty'
};