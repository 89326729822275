import { post, fetch } from '@/service';
import store from '@/store';
var baseURL = '/api/';

var showLoading = function showLoading() {
  store.dispatch('common/showLoading', true);
};

export var sendCode = function sendCode(data) {
  showLoading();
  return fetch("".concat(baseURL, "/user/getCode"), data);
};
export var codeVerification = function codeVerification(data) {
  return post("".concat(baseURL, "/member/codeVerification"), data);
};
export var userRegisterTel = function userRegisterTel(data) {
  showLoading();
  return post("".concat(baseURL, "/member/registerByTel"), data);
};
export var userRegisterEmail = function userRegisterEmail(data) {
  showLoading();
  return post("".concat(baseURL, "/member/registerByEmail"), data);
};
export var forgetByTel = function forgetByTel(data) {
  showLoading();
  return post("".concat(baseURL, "/member/forgetByTel"), data);
};
export var forgetByEmail = function forgetByEmail(data) {
  showLoading();
  return post("".concat(baseURL, "/member/forgetByEmail"), data);
};
export var loginByCode = function loginByCode(data) {
  showLoading();
  return post("".concat(baseURL, "/member/loginByCode"), data);
};
export var login = function login(data) {
  showLoading();
  return post("".concat(baseURL, "/member/loginByTelOrEmail"), data);
};
export var logout = function logout() {
  showLoading();
  return post("".concat(baseURL, "/user/logout"));
};
export var selectUserInfo = function selectUserInfo(data) {
  return fetch("".concat(baseURL, "/user/userInfo"), data);
};
export var getInvitationUrl = function getInvitationUrl() {
  return fetch("".concat(baseURL, "/user/getInvitationUrl"));
};
export var getTeamList = function getTeamList(data) {
  return fetch("".concat(baseURL, "/user/getMyRecom"), data);
};
export var getMyTeam = function getMyTeam(data) {
  return fetch("".concat(baseURL, "/user/getMyTeam"), data);
};
export var updateHeadImg = function updateHeadImg(data) {
  showLoading();
  return post("".concat(baseURL, "/user/updateHeadImg"), data);
};
export var updateNickname = function updateNickname(data) {
  showLoading();
  return post("".concat(baseURL, "/user/updateNickname"), data);
};
export var updateContactNumber = function updateContactNumber(data) {
  showLoading();
  return post("".concat(baseURL, "/user/updateContactNumber"), data);
};
export var setNewTelOrEmail = function setNewTelOrEmail(data) {
  showLoading();
  return post("".concat(baseURL, "/user/setNewTelOrEmail"), data);
};
export var updateArea = function updateArea(data) {
  // showLoading();
  return post("".concat(baseURL, "/user/updateArea"), data);
};
export var editPassword = function editPassword(data) {
  showLoading();
  return post("".concat(baseURL, "/user/editPassword"), data);
};
export var addTradPassword = function addTradPassword(data) {
  showLoading();
  return post("".concat(baseURL, "/user/addTradPassword"), data);
};
export var editPasswordByCode = function editPasswordByCode(data) {
  showLoading();
  return post("".concat(baseURL, "/user/updatePassword"), data);
};
export var editRealName = function editRealName(data) {
  showLoading();
  return post("".concat(baseURL, "/user/editRealName"), data);
};
export var addBankcard = function addBankcard(data) {
  showLoading();
  return post("".concat(baseURL, "/user/addBankcard"), data);
};
export var updateBankcard = function updateBankcard(data) {
  showLoading();
  return post("".concat(baseURL, "/user/updateBankcard"), data);
};
export var onlineList = function onlineList() {
  return fetch("".concat(baseURL, "/user/onlineList"));
};
export var addOnline = function addOnline(data) {
  showLoading();
  return post("".concat(baseURL, "/user/addOnline"), data);
};
export var rechargeChannel = function rechargeChannel() {
  showLoading();
  return fetch("".concat(baseURL, "/user/rg_channel"));
};
export var getEarning = function getEarning() {
  showLoading();
  return fetch("".concat(baseURL, "/user/earnings"));
}; // 会员参与公益抽奖创建订单

export var lotteryOrder = function lotteryOrder(data) {
  showLoading();
  return post("".concat(baseURL, "/user/lotteryOrder"), data);
}; // 查询会员参与公益抽奖创建订单

export var queryLotteryOrders = function queryLotteryOrders(data) {
  return fetch("".concat(baseURL, "/user/queryLotteryOrders"), data);
};