import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.reduce";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
import _toConsumableArray from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import Vue from 'vue';
import Router from 'vue-router'; // 避免重复点击底部导航 导致的控制台报错

var routerReplace = Router.prototype.replace;

Router.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch(function (error) {
    return error;
  });
}; // 重写路由的push方法


var VueRouterPush = Router.prototype.push;

Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(function (err) {
    return err;
  });
};

Vue.use(Router);

var routesFiles = require.context('./routes', true, /\.js$/);

var routes = routesFiles.keys().reduce(function (routes, routePath) {
  var value = routesFiles(routePath);
  routes = [].concat(_toConsumableArray(routes), _toConsumableArray(value.default));
  return routes;
}, []);
var router = new Router({
  routes: _toConsumableArray(routes)
});
router.beforeEach(function (to, from, next) {
  document.title = 'BlackRock';
  sessionStorage.setItem('lastPage', from.name);
  var isLogin = !!localStorage.getItem('isLogin');

  if (['my', 'coinRecharge', 'balances'].includes(to.name)) {
    window.plus && plus.navigator.setStatusBarStyle('dark');
  } else {
    window.plus && plus.navigator.setStatusBarStyle('light');
  } // 检查权限


  if (to.meta.requiresAuth) {
    if (isLogin) {
      next();
    } else {
      if (from.name != 'login') {
        next({
          name: 'login'
        });
      } else {
        next(false);
      }
    }
  } else {
    next();
  }
});
export default router;