var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "balance", on: { scroll: _vm.scrollEvent } },
    [
      _c("div", { ref: "balancePlaceholder", staticClass: "placeholder_box" }),
      _c("div", { staticClass: "balance_bottom" }, [
        _c(
          "div",
          { staticClass: "tabs-container" },
          [
            _c(
              "van-tabs",
              {
                attrs: {
                  "lazy-render": false,
                  sticky: _vm.isSticky,
                  "offset-top": 0,
                  "line-height": 2,
                  "line-width": 20,
                  swipeable: "",
                  animated: "",
                  "swipe-threshold": 3,
                  scrollspy: ""
                },
                model: {
                  value: _vm.active,
                  callback: function($$v) {
                    _vm.active = $$v
                  },
                  expression: "active"
                }
              },
              [
                _c(
                  "van-tab",
                  {
                    staticClass: "custom-tab",
                    attrs: { title: _vm.$t("accountLang.lang159") }
                  },
                  [_c("exchangeList")],
                  1
                ),
                _c(
                  "van-tab",
                  {
                    staticClass: "custom-tab",
                    attrs: { title: _vm.$t("accountLang.lang160") }
                  },
                  [_c("futuresList")],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }