export default {
  lang1: 'Trung tâm cá nhân',
  lang2: 'Số dư của tôi (USDT)',
  lang3: 'Số dư bị đóng băng (USDT)',
  lang4: 'Chứng nhận chính',
  lang5: 'Liên kết thẻ ngân hàng',
  lang6: 'Đặt mật khẩu quỹ',
  lang7: 'Đặt mật khẩu đăng nhập',
  lang8: 'Trung tâm trợ giúp',
  lang9: 'Nhóm',
  lang10: 'Lời mời',
  lang11: 'Ngôn ngữ',
  lang12: 'Cài đặt',
  lang13: 'Đăng nhập',
  lang14: 'TRANG CHỦ',
  lang15: 'Số tiền đầu tư ban đầu',
  lang16: 'Số tiền đầu tư giới hạn',
  lang17: 'Số ngày khóa',
  lang18: 'Tiến độ dự án:',
  lang19: 'Chi tiết',
  lang20: 'Quỹ',
  lang21: 'Lợi nhuận trung bình',
  lang22: 'Chu kỳ dự án',
  lang23: 'Số tiền đầu tư ban đầu',
  lang24: 'Giới thiệu quỹ',
  lang25: 'Khoản đầu tư của tôi',
  lang26: 'Tổng giá trị đầu vào:',
  lang27: 'Tích lũy thu nhập:',
  lang28: 'Số lượng vị thế nắm giữ:',
  lang29: 'Hồ sơ giao dịch',
  lang30: 'Tên quỹ:',
  lang31: 'Số ngày khóa:',
  lang32: 'Lợi nhuận hàng ngày:',
  lang33: 'Số tiền đầu tư:',
  lang34: 'Doanh thu ước tính:',
  lang35: 'Thời gian nhận:',
  lang36: 'Trạng thái:',
  lang37: 'Hợp đồng thứ hai',
  lang38: 'Giao ngay',
  lang39: 'Số dư của tôi (USDT)',
  lang40: 'Số dư đóng băng (USDT)',
  lang41: 'My',
  lang42: 'Trung tâm khuyến mại',
  lang43: 'Quản lý tài chính',
  lang44: 'Nạp tiền xu nhanh',
  lang45: 'Rút tiền Cions nhanh',
  lang45a: 'Dịch vụ khách hàng trực tuyến',
  lang46: 'Mời bạn bè cùng kiếm tiền xu',
  lang47: 'Mời bạn bè và nạp tiền để nhận hoa hồng ngay',
  lang48: 'Mã mời:',
  lang49: 'Liên kết liên kết:',
  lang50: 'Hồ sơ tài chính',
  lang51: 'đặt hàng',
  lang52: 'Tìm kiếm quản lý tài chính',
  lang53: 'Hồ sơ gửi tiền và rút tiền',
  lang54: 'Tham gia ngay lập tức',
  lang55: "chủ thẻ",
  lang56: 'Đơn đặt hàng xổ số',
  lang57: 'Không có thêm dữ liệu',
  lang58: 'Đã trúng',
  lang59: 'Không trúng',
  lang60: 'Chọn số',
  lang61: 'Số trúng thưởng',
  lang62: 'Giải thưởng',
  lang63: 'Thời gian đặt hàng',
  lang64: 'Không có dữ liệu',
  lang65: 'Hồ sơ xổ số',
  lang66: 'Xác nhận đơn hàng',
  lang67: 'Mẹo: Nếu đếm ngược kết thúc và không có lựa chọn nào được thực hiện, đơn hàng sẽ được đặt tự động',
  lang68: 'Chưa Mở Thưng',
  lang69: 'Xin chúc mừng!',
  lang70: 'Chúc mừng bạn đã thắng tiền thưởng với',
  lang71: 'Số',
  lang72: 'Số tiền Giải thưởng',
  lang73: 'Xác nhận'
};