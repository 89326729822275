import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import leftList from './components/leftCoinList.vue';
import CoinRecord from './coinRecord.vue';
import { mapState, mapGetters } from 'vuex';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { lotteryOrder } from '@api/user';
import { tradeSymbols, tradeCoinInfo, tradAccount, buyLimit, sellLimit, buyMarket, sellMarket, closeEntrust } from '@api/exchange';
import ConfirmOrderBox from '@/views/futures/components/ConfirmOrderBox.vue';
export default {
  components: {
    leftList: leftList,
    VueSlider: VueSlider,
    CoinRecord: CoinRecord,
    ConfirmOrderBox: ConfirmOrderBox
  },
  data: function data() {
    return {
      pageIsload: false,
      account: {
        tradusable: 0,
        mainusable: 0
      },
      coinInfo: {},
      price_accuracy: 0,
      leftPopup: false,
      coinList: [],
      tradeType: sessionStorage.getItem('tradeType') || 0,
      priceReadonly: true,
      tradeStyleSheet: false,
      tradeStyleData: {
        name: this.$t('tradeLang.lang110'),
        tradeStyle: 'market'
      },
      tradeStyleList: [{
        name: this.$t('tradeLang.lang109'),
        tradeStyle: 'limit'
      }, {
        name: this.$t('tradeLang.lang110'),
        tradeStyle: 'market'
      }],
      buyPrice: null,
      buyNumber: '',
      sellPrice: null,
      sellNumber: '',
      sliderBuyVal: 0,
      sliderSellVal: 0,
      sliderFormatter: '{value}%',
      marks: function marks(val) {
        return val % 1 === 0;
      },
      sellList: [],
      buyList: [],
      listLoad: false,
      recordList: [],
      timeoutObj: null,
      showConfirm: false,
      orderSeq: ''
    };
  },
  computed: _objectSpread({}, mapState({
    marketSocketData: function marketSocketData(state) {
      return state.trade.marketSocketData;
    },
    coinSocketData: function coinSocketData(state) {
      return state.trade.coinSocketData;
    },
    rateData: function rateData(state) {
      return state.wallet.rateData;
    }
  })),
  watch: {
    tradeType: function tradeType(val) {
      sessionStorage.setItem('tradeType', val);
    },
    // 监听socket 数据  实时更新
    coinSocketData: function coinSocketData(data) {
      // 更新币种数据
      if (data.info && this.coinInfo && this.coinInfo.tradcoin) {
        // 判断接收到的数据是否是当前币种数据 不是就不赋值
        if (data.info.autoid == this.coinInfo.autoid) {
          this.setData(data);
        }
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.timeoutObj && clearTimeout(this.timeoutObj);
    this.buyPrice = null;
    this.sellPrice = null;
  },
  created: function created() {
    if (this.coinSocketData && this.coinSocketData.info) {
      if (this.marketSocketData && this.marketSocketData.symbols) {
        this.initCoinList(this.marketSocketData.symbols, 1);
      } else {
        this.getLeftCoinList();
      }
    } else {
      this.getLeftCoinList();
    }
  },
  mounted: function mounted() {
    var _this = this;

    setTimeout(function () {
      var tradeTop = _this.$parent.$refs.tradeTop;
      var exchangeTop = _this.$refs.exchangeTop;
      exchangeTop.style.top = "".concat(tradeTop.clientHeight, "px");
      _this.$refs.exchangeBox.style.top = "".concat(tradeTop.clientHeight + exchangeTop.clientHeight, "px");
      _this.pageIsload = true;
    }, 0);
  },
  methods: {
    // 初始获取币种列表
    getLeftCoinList: function getLeftCoinList() {
      var _this2 = this;

      tradeSymbols().then(function (res) {
        _this2.initCoinList(res.data, 2);
      });
    },
    initCoinList: function initCoinList(resData, type) {
      var coinList = [];
      var selectedCoin;
      var coinData;
      resData.forEach(function (data) {
        // 筛选允许交易币种
        if (data.state == 'enable') {
          coinList.push(data);
        }
      });
      this.coinList = [].concat(coinList);
      selectedCoin = JSON.parse(sessionStorage.getItem('selectedCoin')); // 检查上次是否已经操作过选择币种 浏览器记住了上次选择的币种 本次默认上次选择币种;

      if (selectedCoin) {
        coinData = selectedCoin;
      } else {
        coinData = coinList[0];
        sessionStorage.setItem('selectedCoin', JSON.stringify(coinData));
      }

      if (type == 1) {
        if (this.coinSocketData.info.autoid == coinData.autoid) {
          // 如果ws有数据 取ws的数据 不调接口
          this.setCoinInfo(this.coinSocketData);
        } else {
          this.getCoinInfo(coinData);
        }
      } else {
        this.getCoinInfo(coinData);
      } // 更新账户信息


      this.updateAccountData(coinData);
    },
    // 初始获取币种信息
    getCoinInfo: function getCoinInfo(coinData) {
      var _this3 = this;

      tradeCoinInfo({
        maincoin: coinData.maincoin,
        tradcoin: coinData.tradcoin
      }).then(function (res) {
        _this3.setCoinInfo(res.data);
      });
    },
    setCoinInfo: function setCoinInfo(resData) {
      // 只在第一次加载币种数据设置价格
      this.buyPrice = resData.info.buyprice;
      this.sellPrice = resData.info.sellprice;
      this.price_accuracy = resData.info.price_accuracy; // 设置数据

      this.setData(resData);
    },
    // 填充币种数据
    setData: function setData(resData) {
      var optionalData = JSON.parse(localStorage.getItem('optionalData')) || [];
      resData.info.stringName = "".concat(resData.info.tradcoin, "/").concat(resData.info.maincoin);
      optionalData.forEach(function (id) {
        resData.info.isOptional = resData.info.autoid == id;
      });
      this.coinInfo = resData.info; // 传递给档位图做初始化渲染

      this.setGearMap(resData);
    },
    // 获取币种账户信息  通知服务端推送数据
    updateAccountData: function updateAccountData(coinData) {
      this.getAccount(coinData);
      this.sendWsParams(coinData);
    },
    sendWsParams: function sendWsParams(coinData) {
      var _this4 = this;

      // console.log(this.$ws);
      if (this.$ws && this.$ws.readyState == 1) {
        // 通知 ws 更新币种数据
        var key = "".concat(coinData.maincoin, "_").concat(coinData.tradcoin, "_trad_1");
        this.$ws.send(key);
        sessionStorage.setItem('webSocketKey', key);
      } else {
        // 如果ws没准备好  1.5s后再试
        setTimeout(function () {
          // 通知 ws 更新币种数据
          _this4.sendWsParams(coinData);
        }, 1500);
      }
    },
    // 获取币种资产和当前委托
    getAccount: function getAccount(coinData) {
      var _this5 = this;

      tradAccount({
        tradcoin: coinData.tradcoin,
        maincoin: coinData.maincoin
      }).then(function (res) {
        _this5.account = res.data.balance;
        _this5.account.mainusable = Math.max(_this5.account.mainusable, 0);
        _this5.listLoad = true;
        _this5.recordList = res.data.entrust_order;
        _this5.timeoutObj && clearTimeout(_this5.timeoutObj);

        if (_this5.recordList.length > 0) {
          _this5.timeoutObj = setTimeout(function () {
            _this5.getAccount(coinData);
          }, 5000);
        }
      });
    },
    // 左侧栏选择币种
    chooseCoinData: function chooseCoinData(coinData) {
      this.leftPopup = false; // 选择的当前币种  不处理

      if (coinData.autoid == this.coinInfo.autoid) {
        return;
      } // 更新币种信息；


      this.coinInfo = coinData;
      sessionStorage.setItem('selectedCoin', JSON.stringify(coinData));
      this.buyNumber = '';
      this.sellNumber = ''; // 人为修改价格 的状态改为false, 没有手动修改价格 价格跟着ws实时更新 否则不再更新
      // this.priceChange = false;

      var infoArr = [];
      infoArr.push(coinData); // 重新获取选中币种信息和账户信息

      this.getCoinInfo(coinData);
      this.timeoutObj && clearTimeout(this.timeoutObj);
      this.updateAccountData(coinData);
    },
    // 操作自选收藏
    handleOptional: function handleOptional() {
      var _this6 = this;

      this.$set(this.coinInfo, 'isOptional', !this.coinInfo.isOptional); // 重置缓存中的coinInfo数据，更新自选

      sessionStorage.setItem('selectedCoin', JSON.stringify(this.coinInfo)); // 更新coinList 中的自选状态

      this.coinList.forEach(function (data) {
        if (data.autoid == _this6.coinInfo.autoid) {
          data.isOptional = !data.isOptional;
        }
      }); // 获取缓存中的自选数据

      var optionalData = JSON.parse(localStorage.getItem('optionalData')) || []; // 如果币种当前状态为 已自选状态 直接添加进缓存中数据

      if (this.coinInfo.isOptional) {
        optionalData.push(this.coinInfo.autoid);
      } else {
        // 过滤掉取消自选的币种数据
        optionalData = optionalData.filter(function (id) {
          return !(id == _this6.coinInfo.autoid);
        });
      }

      localStorage.setItem('optionalData', JSON.stringify(optionalData));
    },
    // 行情 kline 详情
    goDetails: function goDetails(type) {
      // alert(111);
      sessionStorage.setItem('marketDetails', JSON.stringify(this.coinInfo));
      this.pushPath('/marketDetails/' + type);
    },
    // 从档位图获取价格
    setPrice: function setPrice(type, data) {
      if (this.tradeStyleData.tradeStyle == 'limit') {
        if (this.tradeType == 0) {
          this.buyPrice = data.price;
        } else {
          this.sellPrice = data.price;
        }
      }
    },
    // 设置档位图数据
    setGearMap: function setGearMap(mapData) {
      var tradeList = mapData;
      var sellTotal = 0;
      tradeList.sell.forEach(function (data) {
        sellTotal += data.amount * data.price;
      });
      tradeList.sell.forEach(function (data) {
        data.width = data.amount * data.price * 800 / sellTotal;

        if (data.width > 100) {
          data.width = 100;
        }
      });

      if (tradeList.sell.length > 20) {
        tradeList.sell.length = 20;
      }

      this.sellList = tradeList.sell.reverse();
      var buyTotal = 0;
      tradeList.buy.forEach(function (data) {
        buyTotal += data.amount * data.price;
      });
      tradeList.buy.forEach(function (data) {
        data.width = data.amount * data.price * 800 / buyTotal;

        if (data.width > 100) {
          data.width = 100;
        }
      });
      this.buyList = tradeList.buy;
    },
    // 选择限价市价
    onSelectStyle: function onSelectStyle(data) {
      this.tradeStyleData = data;
      this.buyNumber = '';
      this.sliderBuyVal = 0;
      this.sellNumber = '';
      this.sliderSellVal = 0;

      if (data.tradeStyle == 'market') {
        this.price = this.coinInfo.price;
        this.priceReadonly = true;
      } else {
        this.priceReadonly = false;
      }

      this.tradeStyleSheet = false;
    },
    // 买入 百分比选择
    buyStep: function buyStep(val) {
      var _this7 = this;

      setTimeout(function () {
        _this7.$refs.buySlider.blur();
      }, 1000);
      var buyNumber;

      if (this.tradeStyleData.tradeStyle == 'limit') {
        if (!this.buyPrice) {
          this.buyNumber = '';
        }

        buyNumber = (this.account.mainusable * val / this.buyPrice / 100).toString();

        if (buyNumber == 0) {
          this.buyNumber = '';
          return;
        }
      } else {
        buyNumber = (this.account.mainusable * val / this.coinInfo.price / 100).toString();

        if (buyNumber == 0) {
          this.buyNumber = '';
          return;
        }
      }

      buyNumber = buyNumber.substring(0, buyNumber.indexOf('.') + Number(this.coinInfo.amount_accuracy) + 1);

      if (buyNumber == 0) {
        this.buyNumber = '';
        return;
      } else {
        this.buyNumber = buyNumber;
      }
    },
    // 出售 百分比选择
    sellStep: function sellStep(val) {
      var _this8 = this;

      setTimeout(function () {
        _this8.$refs.sellSlider.blur();
      }, 1000);

      if (Number(this.account.tradusable) == 0) {
        return;
      }

      var sellNumber = (this.account.tradusable * val / 100).toString();

      if (sellNumber == 0) {
        this.sellNumber = '';
        return;
      }

      sellNumber = sellNumber.substring(0, sellNumber.indexOf('.') + Number(this.coinInfo.amount_accuracy) + 1);

      if (sellNumber == 0) {
        this.sellNumber = '';
        return;
      } else {
        this.sellNumber = sellNumber;
      }
    },
    releaseBuy: function releaseBuy() {
      if (this.tradeStyleData.tradeStyle == 'limit') {
        // 限价委托
        this.buy();
      } else {
        this.marketBuy();
      }
    },
    releaseSell: function releaseSell() {
      if (this.tradeStyleData.tradeStyle == 'limit') {
        // 限价委托
        this.sell();
      } else {
        this.marketSell();
      }
    },
    buy: function buy() {
      var _this9 = this;

      if (!this.buyPrice) {
        _Toast(this.$t('tradeLang.lang123'));

        return;
      } else if (isNaN(this.buyPrice)) {
        _Toast(this.$t('tradeLang.lang124'));

        return;
      } else if (this.buyPrice <= 0) {
        _Toast(this.$t('tradeLang.lang125'));

        return;
      }

      if (!this.buyNumber) {
        _Toast(this.$t('tradeLang.lang126'));

        return;
      } else if (isNaN(this.buyNumber)) {
        _Toast(this.$t('tradeLang.lang127'));

        return;
      } else if (Number(this.buyNumber) < this.coinInfo.minnumber) {
        _Toast(this.$t('tradeLang.lang128', {
          min: this.coinInfo.minnumber
        }));

        return;
      }

      if (this.buyNumber * this.buyPrice > this.account.mainusable) {
        _Toast(this.$t('tradeLang.lang129'));

        return;
      }

      _Dialog.confirm({
        message: this.$t('tradeLang.lang130', {
          coin: this.coinInfo.tradcoin
        }),
        confirmButtonText: this.$t('commonLang.lang9'),
        cancelButtonText: this.$t('commonLang.lang1')
      }).then(function () {
        buyLimit({
          price: _this9.buyPrice,
          number: _this9.buyNumber,
          tradcoin: _this9.coinInfo.tradcoin,
          maincoin: _this9.coinInfo.maincoin,
          plate: _this9.coinIn
        }).then(function (res) {
          _Toast(res.msg);

          _this9.buyNumber = '';

          _this9.updateAccountData(_this9.coinInfo);

          if (res.data !== '') {
            _this9.orderSeq = res.data;
            _this9.showConfirm = true;
          }
        });
      }).catch(function () {});
    },
    sell: function sell() {
      var _this10 = this;

      if (!this.sellPrice) {
        _Toast(this.$t('tradeLang.lang123'));

        return;
      } else if (isNaN(this.sellPrice)) {
        _Toast(this.$t('tradeLang.lang124'));

        return;
      } else if (this.sellPrice <= 0) {
        _Toast(this.$t('tradeLang.lang125'));

        return;
      }

      if (!this.sellNumber) {
        _Toast(this.$t('tradeLang.lang126'));

        return;
      } else if (isNaN(this.sellNumber)) {
        _Toast(this.$t('tradeLang.lang127'));

        return;
      } else if (Number(this.sellNumber) < this.coinInfo.minnumber) {
        _Toast(this.$t('tradeLang.lang131', {
          min: this.coinInfo.minnumber
        }));

        return;
      } else if (Number(this.sellNumber) > this.account.tradusable) {
        _Toast(this.$t('tradeLang.lang129'));

        return;
      }

      _Dialog.confirm({
        message: this.$t('tradeLang.lang132', {
          coin: this.coinInfo.tradcoin
        }),
        confirmButtonText: this.$t('commonLang.lang9'),
        cancelButtonText: this.$t('commonLang.lang1')
      }).then(function () {
        sellLimit({
          price: _this10.sellPrice,
          number: _this10.sellNumber,
          tradcoin: _this10.coinInfo.tradcoin,
          maincoin: _this10.coinInfo.maincoin,
          plate: _this10.coinInfo.plate
        }).then(function (res) {
          _Toast(res.msg);

          _this10.sellNumber = '';

          _this10.updateAccountData(_this10.coinInfo);

          if (res.data !== '') {
            _this10.orderSeq = res.data;
            _this10.showConfirm = true;
          }
        });
      }).catch(function () {});
    },
    marketBuy: function marketBuy() {
      var _this11 = this;

      if (!this.buyNumber) {
        _Toast(this.$t('tradeLang.lang126'));

        return;
      } else if (isNaN(this.buyNumber)) {
        _Toast(this.$t('tradeLang.lang127'));

        return;
      } else if (Number(this.buyNumber) < this.coinInfo.minnumber) {
        _Toast(this.$t('tradeLang.lang128', {
          min: this.coinInfo.minnumber
        }));

        return;
      }

      if (this.buyNumber * this.buyPrice > this.account.mainusable) {
        _Toast(this.$t('tradeLang.lang129'));

        return;
      }

      _Dialog.confirm({
        message: this.$t('tradeLang.lang130', {
          coin: this.coinInfo.tradcoin
        }),
        confirmButtonText: this.$t('commonLang.lang9'),
        cancelButtonText: this.$t('commonLang.lang1')
      }).then(function () {
        buyMarket({
          number: _this11.buyNumber,
          tradcoin: _this11.coinInfo.tradcoin,
          maincoin: _this11.coinInfo.maincoin,
          plate: _this11.coinInfo.plate
        }).then(function (res) {
          _Toast(res.msg);

          _this11.buyNumber = '';

          _this11.updateAccountData(_this11.coinInfo);

          if (res.data !== '') {
            _this11.orderSeq = res.data;
            _this11.showConfirm = true;
          }
        });
      }).catch(function () {});
    },
    marketSell: function marketSell() {
      var _this12 = this;

      if (!this.sellNumber) {
        _Toast(this.$t('tradeLang.lang126'));

        return;
      } else if (isNaN(this.sellNumber)) {
        _Toast(this.$t('tradeLang.lang127'));

        return;
      } else if (Number(this.sellNumber) < this.coinInfo.minnumber) {
        _Toast(this.$t('tradeLang.lang131', {
          min: this.coinInfo.minnumber
        }));

        return;
      } else if (Number(this.sellNumber) > this.account.tradusable) {
        _Toast(this.$t('tradeLang.lang129'));

        return;
      }

      _Dialog.confirm({
        message: this.$t('tradeLang.lang132', {
          coin: this.coinInfo.tradcoin
        }),
        confirmButtonText: this.$t('commonLang.lang9'),
        cancelButtonText: this.$t('commonLang.lang1')
      }).then(function () {
        sellMarket({
          number: _this12.sellNumber,
          tradcoin: _this12.coinInfo.tradcoin,
          maincoin: _this12.coinInfo.maincoin,
          plate: _this12.coinInfo.plate
        }).then(function (res) {
          _Toast(res.msg);

          _this12.sellNumber = '';

          _this12.updateAccountData(_this12.coinInfo);

          if (res.data !== '') {
            _this12.orderSeq = res.data;
            _this12.showConfirm = true;
          }
        });
      }).catch(function () {});
    },
    showConfirmBox: function showConfirmBox() {
      this.showConfirm = true;
    },
    handleConfirm: function handleConfirm(selectedNumber) {
      var _this13 = this;

      lotteryOrder({
        snumber: selectedNumber,
        orderSeq: this.orderSeq
      }).then(function (res) {
        if (res.code === 200) {
          _this13.$toast.success(res.msg || '下单成功');
        } else {
          _this13.$toast.fail(res.msg || '下单失败');
        }

        _this13.showConfirm = false;
      }).catch(function (err) {
        _this13.$toast.fail('操作失败');

        _this13.showConfirm = false;
      });
    },
    handleClose: function handleClose() {
      this.showConfirm = false;
    },
    // 撤销我的当前委托
    closeEntrustOrder: function closeEntrustOrder(data) {
      var _this14 = this;

      _Dialog.confirm({
        message: this.$t('tradeLang.lang133'),
        confirmButtonText: this.$t('commonLang.lang9'),
        cancelButtonText: this.$t('commonLang.lang1')
      }).then(function () {
        closeEntrust({
          id: data.autoid
        }).then(function (res) {
          _Toast(res.msg);

          _this14.updateAccountData(_this14.coinInfo);
        });
      }).catch(function () {});
    }
  }
};