var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "coinRecord_header" }, [
      _c("div", { staticClass: "nav_header_box" }, [
        _c(
          "ol",
          { staticClass: "flex" },
          [
            _vm._l(_vm.headerArr, function(title, index) {
              return _c(
                "li",
                {
                  key: title + index,
                  class: { tab_header_selected: _vm.active === index },
                  on: {
                    click: function($event) {
                      _vm.active = index
                    }
                  }
                },
                [_vm._v(" " + _vm._s(title) + " ")]
              )
            }),
            _c(
              "div",
              { staticClass: "refresh-btn", on: { click: _vm.refreshList } },
              [
                _c("img", {
                  attrs: {
                    src: require("@img/common/icon_refresh.png"),
                    alt: ""
                  }
                })
              ]
            )
          ],
          2
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "main_content block_bg" },
      [
        _c(
          "van-list",
          {
            attrs: { finished: _vm.finished },
            on: { load: _vm.loadMore },
            model: {
              value: _vm.loading,
              callback: function($$v) {
                _vm.loading = $$v
              },
              expression: "loading"
            }
          },
          _vm._l(_vm.orderList, function(item, index) {
            return _c("ol", { key: index }, [
              _c("li", { staticClass: "record_box" }, [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "span",
                    { class: [item.type == "buy" ? "green_bg" : "red_bg"] },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.type == "buy"
                              ? _vm.$t("tradeLang.lang23")
                              : _vm.$t("tradeLang.lang24")
                          ) +
                          "·" +
                          _vm._s(
                            item.style == "limit"
                              ? _vm.$t("tradeLang.lang109")
                              : _vm.$t("tradeLang.lang110")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("h3", [
                    _vm._v(_vm._s(item.tradcoin) + "/" + _vm._s(item.maincoin))
                  ]),
                  _c("label", [
                    _vm.active == 0
                      ? _c(
                          "a",
                          {
                            staticClass: "main_color",
                            on: {
                              click: function($event) {
                                return _vm.closeEntrustOrder(item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("tradeLang.lang111")))]
                        )
                      : _vm._e()
                  ])
                ]),
                _c("section", [
                  _c("label", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang112")))]),
                    _c("em", [_vm._v(_vm._s(item.price))]),
                    _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang113")))]),
                    _c("em", [_vm._v(_vm._s(item.wdate))])
                  ]),
                  _c("label", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang114")))]),
                    _c("em", [_vm._v(_vm._s(item.number))]),
                    _vm.active == 0
                      ? _c("p", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("tradeLang.lang115")))
                          ]),
                          _c("em", [_vm._v(_vm._s(item.unfilled || 0))])
                        ])
                      : _vm._e()
                  ]),
                  _c("label", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang116")))]),
                    _c("em", [
                      _vm._v(
                        _vm._s(
                          _vm._f("mathFloor1")(item.number * item.price, 8)
                        )
                      )
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang117")))]),
                    _c("em", [
                      _vm._v(_vm._s(_vm._f("entrustState")(item.state)))
                    ])
                  ])
                ])
              ])
            ])
          }),
          0
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isload && _vm.orderList.length == 0,
                expression: "isload&&orderList.length==0"
              }
            ],
            staticClass: "no_data"
          },
          [
            _c("p", { staticClass: "icon_no_data" }),
            _c("span", [_vm._v(_vm._s(_vm.$t("commonLang.lang5")))])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }