var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "total_balance" }, [
      _c("p", [_vm._v(_vm._s(_vm.$t("accountLang.lang162")) + "(USDT)")]),
      _c("div", [_c("h2", [_vm._v(_vm._s(_vm.exchangeAmount.usdt || "0.00"))])])
    ]),
    _c("div", { staticClass: "currencyAbout" }, [
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.rechargeChannelInfo()
            }
          }
        },
        [
          _c("img", {
            staticClass: "icon currencyIcon",
            attrs: { src: require("@img/common/icon_deposit.png"), alt: "" }
          }),
          _c("p", [_vm._v(_vm._s(_vm.$t("accountLang.lang163")))])
        ]
      ),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.pushPath("/coinWithdraw")
            }
          }
        },
        [
          _c("img", {
            staticClass: "icon currencyIcon",
            attrs: { src: require("@img/common/icon_withdraw.png"), alt: "" }
          }),
          _c("p", [_vm._v(_vm._s(_vm.$t("accountLang.lang164")))])
        ]
      ),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.pushPath("/coinTransfer")
            }
          }
        },
        [
          _c("img", {
            staticClass: "icon currencyIcon",
            attrs: { src: require("@img/common/icon_transfer.png"), alt: "" }
          }),
          _c("p", [_vm._v(_vm._s(_vm.$t("accountLang.lang165")))])
        ]
      )
    ]),
    _c("p", { staticClass: "asset_label" }, [
      _vm._v(_vm._s(_vm.$t("accountLang.lang166")))
    ]),
    _c(
      "div",
      { staticClass: "exchange-list-container" },
      _vm._l(_vm.filteredExchangeCoinList, function(item, index) {
        return _c(
          "ol",
          {
            key: index + item.coin + item.blockname,
            staticClass: "coin_list",
            on: {
              click: function($event) {
                return _vm.showRecord(item)
              }
            }
          },
          [
            _c("li", { staticClass: "flex_between" }, [
              _c("h4", { staticClass: "main_color" }, [
                _vm._v(_vm._s(item.coin))
              ]),
              _c("i", { staticClass: "iconfont icon-arrow-right" })
            ]),
            _c("li", { staticClass: "assets_info" }, [
              _c("dl", [
                _c("dt", [_vm._v(_vm._s(_vm.$t("tradeLang.lang42")))]),
                _c("dt", { staticClass: "text_hide" }, [
                  _vm._v(_vm._s(_vm._f("mathFloor")(item.usable, 4)))
                ])
              ]),
              _c("dl", [
                _c("dt", [_vm._v(_vm._s(_vm.$t("tradeLang.lang43")))]),
                _c("dt", { staticClass: "text_hide" }, [
                  _vm._v(_vm._s(_vm._f("mathFloor")(item.disable, 4)))
                ])
              ]),
              _c("dl", [
                _c("dt", [
                  _vm._v(
                    _vm._s(_vm.$t("tradeLang.lang44")) +
                      "(" +
                      _vm._s(_vm.rateData.coin) +
                      ")"
                  )
                ]),
                _c("dt", { staticClass: "text_hide" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("mathFloor")(
                        (Number(item.usable) + Number(item.disable)) *
                          item.usdt *
                          _vm.rateData.price,
                        2
                      )
                    )
                  )
                ])
              ])
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }