import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import coinExchange from '@/views/coinExchange/mainIndex.vue';
import cycle from '@/views/futures/cycle.vue';
export default {
  components: {
    coinExchange: coinExchange,
    cycle: cycle
  },
  data: function data() {
    return {
      active: Number(sessionStorage.getItem('tradeActive')) || 1
    };
  },
  watch: {
    active: function active(val) {
      sessionStorage.setItem('tradeActive', val);
    }
  },
  created: function created() {
    this.$store.dispatch('common/showFooter', true); // this.$store.dispatch('common/updateSelectedFooter', 2)
  },
  mounted: function mounted() {
    var tradeTop = this.$refs.tradeTop;
    tradeTop.style.paddingTop = "".concat(this.$barHeight, "px");
    this.$refs.tradeBox.style.marginTop = "".concat(tradeTop.clientHeight, "px");
  },
  methods: {
    goBack: function goBack() {
      // 方案1: 如果有上一页历史记录,则返回上一页
      // if (window.history.length > 1) {
      //   this.$router.back()
      // } else {
      //   // 如果没有历史记录,则导航到市场页
      //   this.pushPath('/market')
      // }
      // 方案2: 直接返回市场页
      this.pushPath('/market');
    }
  }
};