export default {
  lang1: 'Trang chủ',
  lang2: 'Đăng ký tiền tệ mới của IEO',
  lang3: 'Mã thông báo sưu tập sẽ được phát hành hàng ngày',
  lang4: 'Bí mật',
  lang5: 'Không mở',
  lang6: 'Đã kết thúc',
  lang7: 'Giá',
  lang8: 'Thời gian bắt đầu',
  lang9: 'Tổng số đã phát hành',
  lang10: 'Thời gian kết thúc',
  lang11: 'Số tiền thặng dư',
  lang12: 'Thời gian khóa',
  lang13: 'D',
  lang14: 'Mua {coin}',
  lang15: 'Tiền thanh toán',
  lang15a: 'Thanh toán',
  lang16: 'số dư',
  lang17: 'Sử dụng tất cả',
  lang18: 'Vui lòng nhập số lượng mua',
  lang19: 'Thanh toán:',
  lang20: 'Quà tặng:',
  lang21: 'Bản ghi đơn hàng',
  lang22: 'Phát hành',
  lang23: 'Cam kết IEO',
  lang24: 'Hoàn tiền',
  lang25: 'Vui lòng nhập đúng số lượng mua',
  lang26: 'Tổng số tiền còn lại không đủ',
  lang27: 'Bạn có chắc chắn muốn đổi {number}{coin} không?',
  lang28: 'Thông báo',
  lang29: 'Chưa có thông báo nào',
  lang30: 'Chi tiết thông báo',
  lang31: 'Nhập mã thông báo để tìm kiếm',
  lang32: 'Khai thác mỗi ngày',
  lang33: 'Lực lượng càng cao, khai thác càng nhiều',
  lang34: 'Giao dịch',
  lang35: 'Tăng',
  lang36: 'Giảm',
  lang37: 'Thực tế',
  lang38: 'Hợp đồng tương lai',
  lang39: 'Chu kỳ',
  lang40: 'Yêu thích',
  lang41: 'Tất cả',
  lang42: 'Mian',
  lang43: 'Tiếp theo',
  lang44: 'Khối lượng',
  lang45: 'Giá',
  lang46: 'Thay đổi%',
  lang47: 'Khai thác bị khóa',
  lang48: 'Quỹ được quản lý',
  lang49: 'Thu nhập hôm nay',
  lang50: 'Thu nhập tích lũy',
  lang51: 'Đơn hàng được quản lý',
  lang52: 'Lợi nhuận hàng ngày',
  lang53: 'ngày',
  lang54: 'Xác nhận đơn hàng',
  lang55: 'Lợi nhuận',
  lang56: 'Chu kỳ',
  lang57: 'Giới hạn đơn',
  lang58: 'Thời gian trả cổ tức',
  lang59: 'Hàng ngày',
  lang60: 'Số tiền ký quỹ',
  lang61: 'Trả lại khi hết hạn',
  lang62: 'Hoàn trả sớm',
  lang63: 'Vui lòng nhập số tiền ký quỹ',
  lang64: 'Doanh thu ước tính',
  lang65: 'Vui lòng nhập số tiền ký quỹ chính xác',
  lang66: 'Số dư tài khoản không đủ',
  lang67: 'Số tiền không nằm trong giới hạn đơn',
  lang68: 'Khóa hồ sơ',
  lang69: 'Đang chạy',
  lang70: 'Hoàn tất',
  lang71: 'Đã đổi',
  lang72: 'Thu nhập tích lũy',
  lang73: 'Thời gian quản lý',
  lang74: 'Thời gian hết hạn',
  lang75: 'Xác định việc đổi sớm quyền lưu ký, phí xử lý tương ứng sẽ được khấu trừ cho việc đổi sớm',
  lang76: 'Tiền điện tử phổ biến',
  lang77: 'Tiền tệ ngoại hối',
  lang78: 'Vàng quốc tế'
};