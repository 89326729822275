import { selectBanner, selectNotices, selectAreaCode, selectAboutUs } from '@api/common';
var state = {
  isLogin: !!localStorage.getItem('isLogin'),
  // 登录状态
  showFooter: false,
  // 是否显示底部导航
  selectedFooter: 1,
  // 底部导航位置
  showLoading: false,
  // 遮罩loading
  versionData: {},
  isAppUpdate: false,
  city: null,
  // 城市
  headerHeight: 0,
  // 顶部栏高度
  areaCode: [],
  bannerList: [],
  noticeList: [],
  helpList: [],
  usInfo: {},
  onlineUrl: '',
  countryList: [],
  offsetTime: 0,
  serviceLoaded: false,
  serviceUrl: '',
  theme: 'green' //全局主题色

};
var mutations = {
  LOGIN_STATUS: function LOGIN_STATUS(state, val) {
    state.isLogin = val;
  },
  SHOW_FOOTER: function SHOW_FOOTER(state, val) {
    state.showFooter = val;
  },
  SELECTED_FOOTER: function SELECTED_FOOTER(state, val) {
    state.selectedFooter = val;
  },
  SHOW_LOADING: function SHOW_LOADING(state, val) {
    state.showLoading = val;
  },
  VERSION_DATA: function VERSION_DATA(state, val) {
    if (val.nowCode != val.code) {
      state.isAppUpdate = true;
    }

    state.versionData = val;
  },
  CITY: function CITY(state, val) {
    state.city = val;
  },
  HEADER_HEIGHT: function HEADER_HEIGHT(state, val) {
    state.headerHeight = val;
  },
  AREA_CODE: function AREA_CODE(state, val) {
    state.areaCode = val;
  },
  COUNTRY_LIST: function COUNTRY_LIST(state, val) {
    state.countryList = val;
  },
  BANNER_LIST: function BANNER_LIST(state, val) {
    state.bannerList = val;
  },
  NOTICE_LIST: function NOTICE_LIST(state, val) {
    state.noticeList = val;
  },
  HELP_LIST: function HELP_LIST(state, val) {
    state.helpList = val;
  },
  US_INFO: function US_INFO(state, val) {
    state.usInfo = val;
  },
  ONLINE_URL: function ONLINE_URL(state, val) {
    state.onlineUrl = val;
  },
  OFFSET_TIME: function OFFSET_TIME(state, val) {
    state.offsetTime = val;
  }
};
var actions = {
  updateLoginStatus: function updateLoginStatus(_ref, data) {
    var commit = _ref.commit;
    data ? localStorage.setItem('isLogin', data) : localStorage.removeItem('isLogin');
    commit('LOGIN_STATUS', data);
  },
  showFooter: function showFooter(_ref2, data) {
    var commit = _ref2.commit;
    commit('SHOW_FOOTER', data);
  },
  updateSelectedFooter: function updateSelectedFooter(_ref3, data) {
    var commit = _ref3.commit;
    commit('SELECTED_FOOTER', data);
  },
  showLoading: function showLoading(_ref4, data) {
    var commit = _ref4.commit;
    commit('SHOW_LOADING', data);
  },
  setVersionData: function setVersionData(_ref5, data) {
    var commit = _ref5.commit;
    commit('VERSION_DATA', data);
  },
  setOffsetTime: function setOffsetTime(_ref6, data) {
    var commit = _ref6.commit;
    commit('OFFSET_TIME', data);
  },
  setCity: function setCity(_ref7, data) {
    var commit = _ref7.commit;
    // if(state.city!=data){
    // updateArea({area:data})
    commit('CITY', data); // }
  },
  setNavHeaderHeight: function setNavHeaderHeight(_ref8, data) {
    var commit = _ref8.commit;
    commit('HEADER_HEIGHT', data);
  },
  // 获取地区编码
  getAreaCode: function getAreaCode(_ref9) {
    var commit = _ref9.commit;

    if (!state.areaCode.length) {
      selectAreaCode().then(function (res) {
        commit('AREA_CODE', res.data);
      });
    }
  },
  // 获取地区编码
  getBanner: function getBanner(_ref10, flag) {
    var commit = _ref10.commit;

    if (!state.bannerList.length || flag) {
      selectBanner({
        type: 'app'
      }).then(function (res) {
        commit('BANNER_LIST', res.data);
      });
    }
  },
  getNotice: function getNotice(_ref11, flag) {
    var commit = _ref11.commit;

    if (!state.noticeList.length || flag) {
      selectNotices({
        type: 'notice'
      }).then(function (res) {
        commit('NOTICE_LIST', res.data);
      });
    }
  },
  getHelpList: function getHelpList(_ref12) {
    var commit = _ref12.commit;

    if (!state.helpList.length) {
      selectNotices({
        type: 'help'
      }).then(function (res) {
        commit('HELP_LIST', res.data);
      });
    }
  },
  getUsInfo: function getUsInfo(_ref13) {
    var commit = _ref13.commit;

    if (!state.usInfo.length) {
      selectAboutUs().then(function (res) {
        commit('US_INFO', res.data);
      });
    }
  },
  // 获取地区编码
  getOnlineUrl: function getOnlineUrl(_ref14) {
    var commit = _ref14.commit;

    if (!state.onlineUrl) {
      getChatLink().then(function (res) {
        commit('ONLINE_URL', res.data);
      });
    }
  },
  // 获取国家地区
  getCountry: function getCountry(_ref15) {
    var commit = _ref15.commit;

    if (!state.countryList.length) {
      selectCountry().then(function (res) {
        commit('COUNTRY_LIST', res.data);
      });
    }
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};