export default {
  lang1: 'Personal Center',
  lang2: 'My Balance(USDT)',
  lang3: 'Frozen Balance(USDT)',
  lang4: 'Primary Certification',
  lang5: 'Bind Bank Card',
  lang6: 'Set Fund Password',
  lang7: 'Set Login Password',
  lang8: 'Help Center',
  lang9: 'Team',
  lang10: 'Invitation',
  lang11: 'Language',
  lang12: 'Settings',
  lang13: 'Log in',
  lang14: 'HOME',
  lang15: 'Initial Investment Amount',
  lang16: 'Limited Investment Amount',
  lang17: 'Lockout Days',
  lang18: 'Project Progress',
  lang19: 'Details',
  lang20: 'Fund',
  lang21: 'Average Return',
  lang22: 'Project Cycle',
  lang23: 'Initial Investment Amount',
  lang24: 'Fund Introduction',
  lang25: 'My Investment',
  lang26: 'Total Input Value:',
  lang27: 'Accumulates Income:',
  lang28: 'Number of Positions Held:',
  lang29: 'Transaction Records',
  lang30: 'Fund Name:',
  lang31: 'Lockout Days:',
  lang32: 'Daily Yield:',
  lang33: 'Investment Amount:',
  lang34: 'Estimated Revenue:',
  lang35: 'Time of Receipt:',
  lang36: 'State:',
  lang37: 'Second Contract',
  lang38: 'Spot',
  lang39: 'My Balance(USDT)',
  lang40: 'Frozen Balance(USDT)',
  lang41: 'My',
  lang42: 'Promotion Center',
  lang43: 'Financial Management',
  lang44: 'Quick Coin Charging',
  lang45: 'Quick Withdrawal of Cions',
  lang45a: 'Online Service',
  lang46: 'Invite friends to earn coins together',
  lang47: 'Invite friends and recharge to get commission immediately',
  lang48: 'Invite code:',
  lang49: 'Affiliate Links:',
  lang50: 'Financial Records',
  lang51: 'order',
  lang52: 'Search financial management',
  lang53: 'Deposit and Withdrawal Records',
  lang54: 'Immediate Participation',
  lang55: "cardholder",
  lang56: 'Lottery Orders',
  lang57: 'No More Data',
  lang58: 'Complete',
  lang59: 'Fail',
  lang60: 'Select Numbers',
  lang61: 'Winning Numbers',
  lang62: 'Prize',
  lang63: 'Order Time',
  lang64: 'No Data',
  lang65: 'Lottery Records',
  lang66: 'Confirm Order',
  lang67: 'Tips: If the countdown ends and no selection is made, the order will be placed automatically',
  lang68: 'Not Drawn',
  lang69: 'Congratulations !',
  lang70: 'Congratulations on winning a bonus with your',
  lang71: 'Number',
  lang72: 'Prize Amount',
  lang73: 'Confirm'
};