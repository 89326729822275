var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mask", on: { click: _vm.handleClose } }),
    _c("div", { staticClass: "bonus_box", attrs: { id: _vm.id } }, [
      _c("div", { staticClass: "header" }, [
        _vm._v(" " + _vm._s(_vm.$t("addLang.lang69")) + " ")
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "bonus_text" }, [
          _vm._v(" " + _vm._s(_vm.$t("addLang.lang70"))),
          _c("br"),
          _c("h5", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("addLang.lang51")) +
                " " +
                _vm._s(_vm.orderId) +
                " !"
            )
          ])
        ]),
        _c("div", { staticClass: "bonus_info" }, [
          _c("div", { staticClass: "info_row" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("addLang.lang71")) + ":")
            ]),
            _c("span", { staticClass: "value" }, [_vm._v(_vm._s(_vm.number))])
          ]),
          _c("div", { staticClass: "info_row" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("addLang.lang72")) + ":")
            ]),
            _c("span", { staticClass: "value prize" }, [
              _vm._v(_vm._s(_vm.prizeAmount) + " USDT")
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "confirm_button", on: { click: _vm.handleConfirm } },
        [_vm._v(" " + _vm._s(_vm.$t("addLang.lang72")) + " ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }