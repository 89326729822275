var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serviceUrl
    ? _c(
        "div",
        {
          ref: "serviceBtn",
          staticClass: "float-service-btn",
          style: _vm.buttonStyle,
          on: {
            click: _vm.handleClick,
            touchstart: function($event) {
              $event.preventDefault()
              return _vm.startDrag($event)
            },
            touchmove: function($event) {
              $event.preventDefault()
              return _vm.onDrag($event)
            },
            touchend: function($event) {
              $event.preventDefault()
              return _vm.endDrag($event)
            },
            mousedown: function($event) {
              $event.preventDefault()
              return _vm.startDrag($event)
            },
            mousemove: function($event) {
              $event.preventDefault()
              return _vm.onDrag($event)
            },
            mouseup: function($event) {
              $event.preventDefault()
              return _vm.endDrag($event)
            },
            mouseleave: _vm.endDrag
          }
        },
        [
          _c("img", {
            attrs: { src: _vm.onlineServiceImg, alt: "online service" }
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }