import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.string.includes";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'TabHeader',
  model: {
    prop: 'active',
    event: 'changeTabHeaderActive'
  },
  props: {
    titleArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    active: {
      type: null,
      default: 0
    },
    line: {
      type: Boolean,
      default: false
    },
    showBack: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    active: function active() {
      var _this = this;

      setTimeout(function () {
        _this.line && _this.setLine();
      }, 0);
    }
  },
  mounted: function mounted() {
    this.setBarHeight();
    this.line && this.setLine();
  },
  methods: {
    setBarHeight: function setBarHeight() {
      var el = this.$refs.tabHeader; // console.dir(el);

      el.style.paddingTop = "".concat(this.$barHeight, "px");
      el.nextElementSibling && (el.nextElementSibling.style.top = "".concat(el.clientHeight, "px"));
    },
    changeActive: function changeActive(index) {
      this.$emit('changeTabHeaderActive', index);
    },
    setLine: function setLine() {
      var el;
      var childNodes = this.$refs.tabHeaderOl.childNodes; // console.dir(childNodes);

      childNodes.forEach(function (element) {
        element.className.includes('tab_header_selected') && (el = element);
      }); // console.dir(el);

      var tabHeaderLine = this.$refs.tabHeaderLine;
      var xlength = el.offsetLeft + el.clientWidth / 2;
      tabHeaderLine.style.transform = "translateX(".concat(xlength, "px) translateX(-50%)");
    }
  }
};