var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-tabbar",
    {
      attrs: { border: false },
      model: {
        value: _vm.selected,
        callback: function($$v) {
          _vm.selected = $$v
        },
        expression: "selected"
      }
    },
    [
      _c(
        "van-tabbar-item",
        {
          staticClass: "text_hide",
          on: {
            click: function($event) {
              return _vm.pushPage("/home")
            }
          }
        },
        [
          _c("p", [
            _vm.selected == 0
              ? _c("img", {
                  attrs: {
                    src: require("@img/" +
                      _vm.$store.state.common.theme +
                      "/icon_selected_0.png"),
                    alt: ""
                  }
                })
              : _vm._e(),
            _vm.selected != 0
              ? _c("img", {
                  attrs: {
                    src: require("@img/" +
                      _vm.$store.state.common.theme +
                      "/icon_default_0.png"),
                    alt: ""
                  }
                })
              : _vm._e()
          ]),
          _c("span", { staticClass: "footer_name text_hide" }, [
            _vm._v(_vm._s(_vm.$t("homeLang.lang1")))
          ])
        ]
      ),
      _c(
        "van-tabbar-item",
        {
          staticClass: "text_hide",
          on: {
            click: function($event) {
              return _vm.pushPage("/market")
            }
          }
        },
        [
          _c("p", [
            _vm.selected == 1
              ? _c("img", {
                  attrs: {
                    src: require("@img/" +
                      _vm.$store.state.common.theme +
                      "/icon_selected_1.png"),
                    alt: ""
                  }
                })
              : _vm._e(),
            _vm.selected != 1
              ? _c("img", {
                  attrs: {
                    src: require("@img/" +
                      _vm.$store.state.common.theme +
                      "/icon_default_1.png"),
                    alt: ""
                  }
                })
              : _vm._e()
          ]),
          _c("span", { staticClass: "footer_name text_hide" }, [
            _vm._v(_vm._s(_vm.$t("commonLang.lang15")))
          ])
        ]
      ),
      _c(
        "van-tabbar-item",
        {
          staticClass: "text_hide",
          on: {
            click: function($event) {
              return _vm.pushPage("/trade")
            }
          }
        },
        [
          _c("p", [
            _vm.selected == 2
              ? _c("img", {
                  attrs: {
                    src: require("@img/" +
                      _vm.$store.state.common.theme +
                      "/icon_selected_2.png"),
                    alt: ""
                  }
                })
              : _vm._e(),
            _vm.selected != 2
              ? _c("img", {
                  attrs: {
                    src: require("@img/" +
                      _vm.$store.state.common.theme +
                      "/icon_default_2.png"),
                    alt: ""
                  }
                })
              : _vm._e()
          ]),
          _c("span", { staticClass: "footer_name text_hide" }, [
            _vm._v(_vm._s(_vm.$t("commonLang.lang14")))
          ])
        ]
      ),
      _c(
        "van-tabbar-item",
        {
          staticClass: "text_hide",
          on: {
            click: function($event) {
              return _vm.pushPage("/balances")
            }
          }
        },
        [
          _c("p", [
            _vm.selected == 3
              ? _c("img", {
                  attrs: {
                    src: require("@img/" +
                      _vm.$store.state.common.theme +
                      "/icon_selected_4.png"),
                    alt: ""
                  }
                })
              : _vm._e(),
            _vm.selected != 3
              ? _c("img", {
                  attrs: {
                    src: require("@img/" +
                      _vm.$store.state.common.theme +
                      "/icon_default_4.png"),
                    alt: ""
                  }
                })
              : _vm._e()
          ]),
          _c("span", { staticClass: "footer_name text_hide" }, [
            _vm._v(_vm._s(_vm.$t("commonLang.lang11")))
          ])
        ]
      ),
      _c(
        "van-tabbar-item",
        {
          staticClass: "text_hide",
          on: {
            click: function($event) {
              return _vm.pushPage("/personal")
            }
          }
        },
        [
          _c("p", [
            _vm.selected == 4
              ? _c("img", {
                  attrs: {
                    src: require("@img/" +
                      _vm.$store.state.common.theme +
                      "/icon_selected_5.png"),
                    alt: ""
                  }
                })
              : _vm._e(),
            _vm.selected != 4
              ? _c("img", {
                  attrs: {
                    src: require("@img/" +
                      _vm.$store.state.common.theme +
                      "/icon_default_5.png"),
                    alt: ""
                  }
                })
              : _vm._e()
          ]),
          _c("span", { staticClass: "footer_name text_hide" }, [
            _vm._v(_vm._s(_vm.$t("addLang.lang41")))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }