import "core-js/modules/es.array.concat";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  data: function data() {
    return {
      showProgress: false,
      dtask: null,
      downloadProgress: 0
    };
  },
  computed: _objectSpread({}, mapState({
    versionData: function versionData(state) {
      return state.common.versionData;
    },
    isAppUpdate: function isAppUpdate(state) {
      return state.common.isAppUpdate;
    }
  })),
  methods: {
    createDownloadTask: function createDownloadTask() {
      var _this = this;

      if (mui.os.android) {
        this.showProgress = true;
        this.dtask = plus.downloader.createDownload(this.versionData.url, {}, function (d, status) {
          if (status == 200) {
            _this.installPackage(d.filename);

            plus.runtime.quit();
          } else {
            plus.nativeUI.alert(_this.$t('plusLang.lang13'), function () {}, '', 'ok');
          }
        });
        this.dtask.addEventListener('statechanged', this.onStateChanged, false);
        this.dtask.start();
      } else {
        plus.runtime.openURL(this.versionData.url);
        plus.runtime.restart();
      }
    },
    // 监听下载任务状态  展示下载进度条进度
    onStateChanged: function onStateChanged() {
      var _this2 = this;

      mui.later(function () {
        var num = _this2.dtask.downloadedSize / _this2.dtask.totalSize;

        if (num && num < 1) {
          _this2.downloadProgress = num;
        }
      }, 200);
    },
    // 安装更新文件
    installPackage: function installPackage(path) {
      var vm = this;
      plus.runtime.install(path, {}, function () {
        plus.nativeUI.closeWaiting();
        console.log('安装wgt文件成功！');
        setTimeout(function () {
          plus.nativeUI.alert(vm.$t('plusLang.lang6'), function () {
            plus.runtime.restart();
          }, '', 'ok');
        }, 3000);
      }, function (e) {
        plus.nativeUI.closeWaiting();
        console.log('安装wgt文件失败[' + e.code + ']：' + e.message);
        plus.nativeUI.alert("".concat(vm.$t('plusLang.lang7'), " ").concat(e.code, "\uFF1A").concat(e.message), function () {}, '', 'ok');
      });
    }
  }
};