var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "coin_exchange" },
    [
      _c("div", { ref: "exchangeTop", staticClass: "exchange_nav main_bg" }, [
        _c("div", { staticClass: "coin_top flex_between" }, [
          _c(
            "label",
            {
              staticClass: "flex",
              on: {
                click: function($event) {
                  _vm.leftPopup = true
                }
              }
            },
            [
              _c("img", {
                attrs: { src: require("@img/common/icon_menu.png"), alt: "" }
              }),
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.coinInfo.tradcoin) +
                    "/" +
                    _vm._s(_vm.coinInfo.maincoin)
                )
              ]),
              _vm.coinInfo.rise >= 0
                ? _c("i", { staticClass: "green_color" }, [
                    _vm._v("+" + _vm._s(_vm.coinInfo.rise) + "%")
                  ])
                : _vm._e(),
              _vm.coinInfo.rise < 0
                ? _c("i", { staticClass: "red_color" }, [
                    _vm._v(_vm._s(_vm.coinInfo.rise) + "%")
                  ])
                : _vm._e()
            ]
          ),
          _c("p", [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.goDetails("trad")
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: require("@img/common/icon_trad.png"), alt: "" }
                })
              ]
            ),
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.pushPath("/allRecord")
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: require("@img/common/icon_info.png"), alt: "" }
                })
              ]
            )
          ])
        ])
      ]),
      _c("div", { ref: "exchangeBox", staticClass: "main_content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageIsload,
                expression: "pageIsload"
              }
            ],
            staticClass: "trade_content flex block_bg"
          },
          [
            _c("div", { staticClass: "left_box" }, [
              _c("div", { staticClass: "left_header flex_between" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang7")))]),
                _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang6")))])
              ]),
              _c(
                "ol",
                { staticClass: "list_box" },
                [
                  _vm._l(_vm.sellList, function(item, index) {
                    return _c(
                      "li",
                      {
                        key: index + "sell",
                        on: {
                          click: function($event) {
                            return _vm.setPrice("sell", item)
                          }
                        }
                      },
                      [
                        _c("div", [
                          _c("i", {
                            staticClass: "red_transparent",
                            style: { width: item.width + "%" }
                          }),
                          _c("p", { staticClass: "flex_between" }, [
                            _c("span", { staticClass: "red_color" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("mathFloor")(
                                    item.price,
                                    _vm.coinInfo.price_accuracy
                                  )
                                )
                              )
                            ]),
                            _c("span", [_vm._v(_vm._s(item.amount))])
                          ])
                        ])
                      ]
                    )
                  }),
                  _vm._l(
                    6 - _vm.sellList.length > 0 ? 6 - _vm.sellList.length : 0,
                    function(n) {
                      return _c("li", { key: n }, [_vm._m(0, true)])
                    }
                  )
                ],
                2
              ),
              _c("section", [
                _c(
                  "h2",
                  {
                    class: [
                      _vm.coinInfo.rise >= 0 ? "green_color" : "red_color",
                      "text_hide"
                    ]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("mathFloor")(
                          _vm.coinInfo.price,
                          _vm.coinInfo.price_accuracy
                        )
                      )
                    )
                  ]
                ),
                _c("p", [
                  _vm._v(
                    "≈ " +
                      _vm._s(_vm.rateData.symbol) +
                      _vm._s(
                        _vm._f("mathFloor")(
                          _vm.coinInfo.price * _vm.rateData.price,
                          2
                        )
                      )
                  )
                ])
              ]),
              _c(
                "ol",
                { staticClass: "list_box" },
                [
                  _vm._l(_vm.buyList, function(item, index) {
                    return _c(
                      "li",
                      {
                        key: index + "buy",
                        on: {
                          click: function($event) {
                            return _vm.setPrice("buy", item)
                          }
                        }
                      },
                      [
                        _c("div", [
                          _c("i", {
                            staticClass: "green_transparent",
                            style: { width: item.width + "%" }
                          }),
                          _c("p", { staticClass: "flex_between" }, [
                            _c("span", { staticClass: "green_color" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("mathFloor")(
                                    item.price,
                                    _vm.coinInfo.price_accuracy
                                  )
                                )
                              )
                            ]),
                            _c("span", [_vm._v(_vm._s(item.amount))])
                          ])
                        ])
                      ]
                    )
                  }),
                  _vm._l(
                    6 - _vm.buyList.length > 0 ? 6 - _vm.buyList.length : 0,
                    function(n) {
                      return _c("li", { key: n }, [_vm._m(1, true)])
                    }
                  )
                ],
                2
              )
            ]),
            _c(
              "div",
              { staticClass: "right_box" },
              [
                _c("ol", { staticClass: "release_nav flex_between" }, [
                  _c(
                    "li",
                    {
                      class: [{ "selected_nav green_bg": _vm.tradeType == 0 }],
                      on: {
                        click: function($event) {
                          _vm.tradeType = 0
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("tradeLang.lang23")))]
                  ),
                  _c(
                    "li",
                    {
                      class: [{ "selected_nav red_bg": _vm.tradeType == 1 }],
                      on: {
                        click: function($event) {
                          _vm.tradeType = 1
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("tradeLang.lang24")))]
                  )
                ]),
                _c("van-action-sheet", {
                  attrs: {
                    actions: _vm.tradeStyleList,
                    "cancel-text": _vm.$t("commonLang.lang1")
                  },
                  on: { select: _vm.onSelectStyle },
                  model: {
                    value: _vm.tradeStyleSheet,
                    callback: function($$v) {
                      _vm.tradeStyleSheet = $$v
                    },
                    expression: "tradeStyleSheet"
                  }
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tradeType == 0,
                        expression: "tradeType==0"
                      }
                    ],
                    staticClass: "release_box"
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "trade_type_box",
                        on: {
                          click: function($event) {
                            _vm.tradeStyleSheet = true
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.tradeStyleData.name) + " "),
                        _c("i", { staticClass: "iconfont icon-down" })
                      ]
                    ),
                    !_vm.priceReadonly
                      ? _c("van-stepper", {
                          attrs: {
                            name: "buyPrice",
                            "button-size": "20px",
                            min: 1 / Math.pow(10, _vm.price_accuracy),
                            step: 1 / Math.pow(10, _vm.price_accuracy)
                          },
                          model: {
                            value: _vm.buyPrice,
                            callback: function($$v) {
                              _vm.buyPrice = $$v
                            },
                            expression: "buyPrice"
                          }
                        })
                      : _vm._e(),
                    _vm.priceReadonly
                      ? _c(
                          "div",
                          { staticClass: "price_readonly_box text_hide" },
                          [_vm._v(_vm._s(_vm.$t("tradeLang.lang106")))]
                        )
                      : _vm._e(),
                    _c("p", { staticClass: "cny_box" }, [
                      !_vm.priceReadonly
                        ? _c("span", [
                            _vm._v(
                              "≈ " +
                                _vm._s(_vm.rateData.symbol) +
                                _vm._s(
                                  _vm._f("mathFloor")(
                                    _vm.buyPrice * _vm.rateData.price,
                                    2
                                  )
                                )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "num_box" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.buyNumber,
                            expression: "buyNumber"
                          }
                        ],
                        attrs: {
                          type: "number",
                          placeholder: _vm.$t("tradeLang.lang6")
                        },
                        domProps: { value: _vm.buyNumber },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.buyNumber = $event.target.value
                          }
                        }
                      }),
                      _c("em", [_vm._v(_vm._s(_vm.coinInfo.tradcoin))])
                    ]),
                    _c("p", { staticClass: "usable_box text_hide" }, [
                      _vm._v(_vm._s(_vm.$t("tradeLang.lang42")) + " "),
                      _c("em", { staticClass: "text_hide" }, [
                        _vm._v(
                          _vm._s(_vm.account.mainusable || "0.00") +
                            _vm._s(_vm.coinInfo.maincoin)
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "slider_box" },
                      [
                        _c("vue-slider", {
                          ref: "buySlider",
                          attrs: {
                            "tooltip-formatter": "{value}%",
                            "process-style": { backgroundColor: "#F0B90A" },
                            "label-style": {
                              color: "#272D42",
                              fontSize: "12px"
                            },
                            adsorb: true,
                            included: true,
                            "dot-size": 10,
                            height: 1,
                            max: 100,
                            marks: _vm.marks,
                            "hide-label": true
                          },
                          on: { change: _vm.buyStep },
                          scopedSlots: _vm._u([
                            {
                              key: "step",
                              fn: function(ref) {
                                var label = ref.label
                                var active = ref.active
                                return [
                                  _c("div", {
                                    class: ["custom-step", { active: active }]
                                  })
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.sliderBuyVal,
                            callback: function($$v) {
                              _vm.sliderBuyVal = $$v
                            },
                            expression: "sliderBuyVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c("p", { staticClass: "usable_box text_hide" }, [
                      _vm._v(_vm._s(_vm.$t("tradeLang.lang107")) + " "),
                      !_vm.priceReadonly
                        ? _c("em", { staticClass: "text_hide" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("mathFloor1")(
                                  _vm.buyPrice * _vm.buyNumber,
                                  8
                                )
                              ) + _vm._s(_vm.coinInfo.maincoin)
                            )
                          ])
                        : _vm._e(),
                      _vm.priceReadonly
                        ? _c("em", { staticClass: "text_hide" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("mathFloor1")(
                                  _vm.coinInfo.price * _vm.buyNumber,
                                  8
                                )
                              ) + _vm._s(_vm.coinInfo.maincoin)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c(
                      "van-button",
                      {
                        staticClass: "green_bg trading_btn",
                        on: {
                          click: function($event) {
                            return _vm.releaseBuy()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("tradeLang.lang23")))]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tradeType == 1,
                        expression: "tradeType==1"
                      }
                    ],
                    staticClass: "release_box"
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "trade_type_box",
                        on: {
                          click: function($event) {
                            _vm.tradeStyleSheet = true
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.tradeStyleData.name) + " "),
                        _c("i", { staticClass: "iconfont icon-down" })
                      ]
                    ),
                    !_vm.priceReadonly
                      ? _c("van-stepper", {
                          attrs: {
                            name: "sellPrice",
                            "button-size": "20px",
                            min: 1 / Math.pow(10, _vm.price_accuracy),
                            step: 1 / Math.pow(10, _vm.price_accuracy)
                          },
                          model: {
                            value: _vm.sellPrice,
                            callback: function($$v) {
                              _vm.sellPrice = $$v
                            },
                            expression: "sellPrice"
                          }
                        })
                      : _vm._e(),
                    _vm.priceReadonly
                      ? _c(
                          "div",
                          { staticClass: "price_readonly_box text_hide" },
                          [_vm._v(_vm._s(_vm.$t("tradeLang.lang106")) + " ")]
                        )
                      : _vm._e(),
                    _c("p", { staticClass: "cny_box" }, [
                      !_vm.priceReadonly
                        ? _c("span", [
                            _vm._v(
                              "≈ " +
                                _vm._s(_vm.rateData.symbol) +
                                _vm._s(
                                  _vm._f("mathFloor")(
                                    _vm.buyPrice * _vm.rateData.price,
                                    2
                                  )
                                )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "num_box" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sellNumber,
                            expression: "sellNumber"
                          }
                        ],
                        attrs: {
                          type: "number",
                          placeholder: _vm.$t("tradeLang.lang6")
                        },
                        domProps: { value: _vm.sellNumber },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.sellNumber = $event.target.value
                          }
                        }
                      }),
                      _c("em", [_vm._v(_vm._s(_vm.coinInfo.tradcoin))])
                    ]),
                    _c("p", { staticClass: "usable_box text_hide" }, [
                      _vm._v(_vm._s(_vm.$t("tradeLang.lang42")) + " "),
                      _c("em", { staticClass: "text_hide" }, [
                        _vm._v(
                          _vm._s(_vm.account.tradusable || "0.00") +
                            " " +
                            _vm._s(_vm.coinInfo.tradcoin)
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "slider_box" },
                      [
                        _c("vue-slider", {
                          ref: "sellSlider",
                          attrs: {
                            "tooltip-formatter": "{value}%",
                            "process-style": { backgroundColor: "#F0B90A" },
                            "label-style": {
                              color: "#272D42",
                              fontSize: "12px"
                            },
                            adsorb: true,
                            included: true,
                            "dot-size": 10,
                            height: 1,
                            max: 100,
                            marks: _vm.marks,
                            "hide-label": true
                          },
                          on: { change: _vm.sellStep },
                          scopedSlots: _vm._u([
                            {
                              key: "step",
                              fn: function(ref) {
                                var label = ref.label
                                var active = ref.active
                                return [
                                  _c("div", {
                                    class: ["custom-step", { active: active }]
                                  })
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.sliderSellVal,
                            callback: function($$v) {
                              _vm.sliderSellVal = $$v
                            },
                            expression: "sliderSellVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c("p", { staticClass: "usable_box text_hide" }, [
                      _vm._v(_vm._s(_vm.$t("tradeLang.lang107")) + " "),
                      !_vm.priceReadonly
                        ? _c("em", { staticClass: "text_hide" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("mathFloor1")(
                                  _vm.sellPrice * _vm.sellNumber,
                                  8
                                )
                              ) + _vm._s(_vm.coinInfo.maincoin)
                            )
                          ])
                        : _vm._e(),
                      _vm.priceReadonly
                        ? _c("em", { staticClass: "text_hide" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("mathFloor1")(
                                  _vm.coinInfo.price * _vm.sellNumber,
                                  8
                                )
                              ) + _vm._s(_vm.coinInfo.maincoin)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c(
                      "van-button",
                      {
                        staticClass: "red_bg trading_btn",
                        on: {
                          click: function($event) {
                            return _vm.releaseSell()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("tradeLang.lang24")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageIsload,
                expression: "pageIsload"
              }
            ],
            staticClass: "trade_list"
          },
          [
            _c(
              "div",
              { staticClass: "record-container" },
              [
                _vm.coinInfo.tradcoin
                  ? _c("CoinRecord", { attrs: { "coin-info": _vm.coinInfo } })
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "van-popup",
        {
          staticClass: "left_popup",
          attrs: { "safe-area-inset-bottom": "", "get-container": "#app" },
          model: {
            value: _vm.leftPopup,
            callback: function($$v) {
              _vm.leftPopup = $$v
            },
            expression: "leftPopup"
          }
        },
        [
          _c("left-list", {
            attrs: { "coin-list-arr": _vm.coinList },
            on: { chooseCoin: _vm.chooseCoinData }
          })
        ],
        1
      ),
      _vm.showConfirm
        ? _c("confirm-order-box", {
            attrs: { id: "confirm-box" },
            on: { confirm: _vm.handleConfirm, close: _vm.handleConfirm }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "flex_between" }, [
        _c("span", { staticClass: "red_color" }, [_vm._v("--")]),
        _c("span", [_vm._v("--")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "flex_between" }, [
        _c("span", { staticClass: "green_color" }, [_vm._v("--")]),
        _c("span", [_vm._v("--")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }