var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "market block_bg" }, [
    _c("div", { ref: "marketTop", staticClass: "market_top" }, [
      _c("ol", { staticClass: "market_nav flex" }, [
        _c(
          "li",
          {
            class: [{ nav_active: _vm.active == 3 }],
            on: {
              click: function($event) {
                _vm.active = 3
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("addLang.lang37")))]
        ),
        _c(
          "li",
          {
            class: [{ nav_active: _vm.active == 1 }],
            on: {
              click: function($event) {
                _vm.active = 1
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("addLang.lang38")))]
        )
      ])
    ]),
    _c(
      "div",
      { ref: "tradeBox" },
      [
        _vm.active == 3 ? _c("secondOrder") : _vm._e(),
        _vm.active == 1 ? _c("spotOrder") : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }