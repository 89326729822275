export default {
  lang1: "首頁",
  lang2: "IEO新幣認籌",
  lang3: "認籌幣種將每日釋放",
  lang4: "立即兌換",
  lang5: "未開放",
  lang6: "已結束",
  lang7: "單價",
  lang8: "開始時間",
  lang9: "發行總量",
  lang10: "結束時間",
  lang11: "剩餘總量",
  lang12: "鎖倉週期",
  lang13: "天",
  lang14: "購買{coin}",
  lang15: "支付幣種",
  lang15a: "支付",
  lang16: "餘額",
  lang17: "全部使用",
  lang18: "請輸入購買數量",
  lang19: "預計支付：",
  lang20: "贈送：",
  lang21: "訂單記錄",
  lang22: "累計釋放",
  lang23: "IEO認籌",
  lang24: "推廣返傭",
  lang25: "請輸入正確的購買數量",
  lang26: "剩餘總量不足",
  lang27: "確定兌換{number}{coin}？",
  lang28: "公告",
  lang29: "暫無公告",
  lang30: "公告詳情",
  lang31: "輸入幣種蒐索",
  lang32: "天天挖礦",
  lang33: "原力越高挖礦越多",
  lang34: "成交榜",
  lang35: "漲幅榜",
  lang36: "跌幅榜",
  lang37: "現貨",
  lang38: "合約",
  lang39: "週期合約",
  lang40: "自選",
  lang41: "全部",
  lang42: "主機板",
  lang43: "創業板",
  lang44: "成交量",
  lang45: "最新價",
  lang46: "24H漲幅",
  lang47: "鎖倉挖礦",
  lang48: "託管資金",
  lang49: "預計今日收益",
  lang50: "累計收益",
  lang51: "託管訂單",
  lang52: "日收益率",
  lang53: "天",
  lang54: "確認訂單",
  lang55: "近日（日收益率）",
  lang56: "託管週期",
  lang57: "單筆限額",
  lang58: "派息時間",
  lang59: "每日",
  lang60: "託管金額",
  lang61: "到期返回",
  lang62: "提前贖回",
  lang63: "請輸入託管金額",
  lang64: "預計收益",
  lang65: "請輸入正確的託管金額",
  lang66: "帳戶餘額不足",
  lang67: "金額不在單筆限額內",
  lang68: "鎖倉記錄",
  lang69: "運行中",
  lang70: "完成",
  lang71: "已贖回",
  lang72: "累計收益",
  lang73: "託管時間",
  lang74: "到期時間",
  lang75: "確定提前贖回託管，提前贖回將扣除相應手續費",
  lang76: '熱門幣種',
  lang77: '外匯幣種',
  lang78: '國際黃金'
};