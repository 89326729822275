import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.string.includes";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapGetters } from 'vuex';
export default {
  props: {
    coinListArr: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      areaType: '',
      coinTab: [],
      coinList: [],
      searchParams: ''
    };
  },
  computed: _objectSpread({}, mapState({
    marketSocketData: function marketSocketData(state) {
      return state.trade.marketSocketData;
    }
  })),
  watch: {
    // 监听socket 数据  实时更新
    marketSocketData: function marketSocketData(data) {
      // 更新行情
      if (data.symbols) {
        this.processData(data.symbols);
      }
    }
  },
  created: function created() {
    // 防止socket更新不及时  先用接口数据填充
    this.processData(this.coinListArr);
  },
  mounted: function mounted() {
    var coinListTop = this.$refs.coinListTop;
    coinListTop.style.paddingTop = "".concat(this.$barHeight, "px");
    this.$refs.searchList.style.top = "".concat(coinListTop.clientHeight + 8, "px");
  },
  methods: {
    // 填充左侧边栏数据
    processData: function processData(resData) {
      var _this = this;

      // 设置自选
      var optionalData = JSON.parse(localStorage.getItem('optionalData')) || [];
      this.coinList = [];
      resData.forEach(function (data) {
        data.stringName = "".concat(data.tradcoin, "/").concat(data.maincoin);
        optionalData.forEach(function (item) {
          if (data.autoid == item.autoid) {
            data.isOptional = true;
          }
        });

        if (!_this.coinTab.includes(data.maincoin)) {
          _this.coinTab.push(data.maincoin);
        }

        if (!_this.areaType) {
          _this.areaType = _this.coinTab[0];
        }

        if (data.state == 'enable') {
          _this.coinList.push(data);
        }
      });
    },
    chooseCoin: function chooseCoin(coinData) {
      this.$emit('chooseCoin', coinData);
    }
  }
};