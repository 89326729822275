var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "drift_box",
        style: { top: _vm.top + "px" },
        attrs: { id: _vm.id },
        on: {
          touchstart: _vm.dragStart,
          touchmove: _vm.dragMove,
          touchend: _vm.dragEnd
        }
      },
      [
        _c("ol", [
          _c("li", { staticClass: "text_hide first_box" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.data.tradcoin) + "/" + _vm._s(_vm.data.maincoin)
              )
            ]),
            _c("i", [_vm._v("·")]),
            _vm.data.type == "buy"
              ? _c("span", { staticClass: "green_color" }, [
                  _vm._v(_vm._s(_vm.$t("tradeLang.lang235")))
                ])
              : _vm._e(),
            _vm.data.type == "sell"
              ? _c("span", { staticClass: "red_color" }, [
                  _vm._v(_vm._s(_vm.$t("tradeLang.lang236")))
                ])
              : _vm._e(),
            _c("i", [_vm._v("·")]),
            _vm.data.type == "buy" &&
            Number(_vm.nowPrice) > Number(_vm.data.price)
              ? _c("span", { staticClass: "green_color" }, [
                  _vm._v(_vm._s(_vm.$t("tradeLang.lang266")))
                ])
              : _vm._e(),
            _vm.data.type == "buy" &&
            Number(_vm.nowPrice) <= Number(_vm.data.price)
              ? _c("span", { staticClass: "red_color" }, [
                  _vm._v(_vm._s(_vm.$t("tradeLang.lang267")))
                ])
              : _vm._e(),
            _vm.data.type == "sell" &&
            Number(_vm.nowPrice) < Number(_vm.data.price)
              ? _c("span", { staticClass: "green_color" }, [
                  _vm._v(_vm._s(_vm.$t("tradeLang.lang266")))
                ])
              : _vm._e(),
            _vm.data.type == "sell" &&
            Number(_vm.nowPrice) >= Number(_vm.data.price)
              ? _c("span", { staticClass: "red_color" }, [
                  _vm._v(_vm._s(_vm.$t("tradeLang.lang267")))
                ])
              : _vm._e()
          ]),
          _c("li", { staticClass: "text_hide_order" }, [
            _vm._v(_vm._s(_vm.$t("tradeLang.lang268")) + _vm._s(_vm.data.price))
          ]),
          _c("li", { staticClass: "text_hide_order" }, [
            _vm._v(
              _vm._s(_vm.$t("tradeLang.lang269")) +
                _vm._s(_vm.data.number) +
                _vm._s(_vm.data.paycoin)
            )
          ]),
          _vm.time !== null
            ? _c(
                "li",
                [
                  _vm._v(_vm._s(_vm.$t("tradeLang.lang270"))),
                  _c("van-count-down", {
                    ref: "countDown",
                    attrs: { time: _vm.time, "auto-start": true, format: "ss" },
                    on: { change: _vm.refreshData, finish: _vm.finish }
                  }),
                  _c("em", { staticClass: "main_color" }, [_vm._v("s")])
                ],
                1
              )
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }