export default {
  lang1: "Log masuk",
  lang2: "Anda juga dapat menggunakan",
  lang3: "Email",
  lang4: "ponsel",
  lang5: "telepon",
  lang6: "Silakan masukkan nomor ponsel anda",
  lang7: "Email",
  lang8: "Silakan masukkan akun email anda",
  lang9: "Sandi log masuk",
  lang9a: "Silakan masukkan sandi log masuk",
  lang10: "Lupa paswordgenericname",
  lang11: "Mendaftar sekarang",
  lang12: "Cari negara/daerah",
  lang13: "Area yang umum digunakan",
  lang14: "Email",
  lang15: "ponsel",
  lang16: "Atur sandi log masuk",
  lang17: "6-20 karakter, terdiri dari huruf dan angka",
  lang18: "Konfirmasi sandi log masuk",
  lang19: "Tolong masukkan kata sandinya lagi",
  lang20: "kode",
  lang21: "Silakan masukkan kode verifikasi",
  lang22: "kirim",
  lang23: "Kode undangan (opsional)",
  lang23a: "Silakan masukkan kode undangan",
  lang24: "Aku sudah membaca dan setuju",
  lang25: "Perjanjian pengguna",
  lang26: "Kebijakan privasi",
  lang27: "Tolong isi alamat email yang benar",
  lang28: "Silakan isi sandi log masuk berisi 6-20 digit dan huruf",
  lang29: "Kedua kata sandi tidak konsisten",
  lang30: "Download Android",
  lang31: "Download iOS",
  lang32: "Setelah mengambil password, mata uang tidak dapat ditarik dalam waktu 24 jam",
  lang33: "Atur ulang sandi",
  lang34: "Email",
  lang35: "ponsel",
  lang36: "Klik untuk log masuk",
  lang37: "Selamat datang di BlackRock",
  lang38: "Selamat datang,",
  lang39: "KYC",
  lang40: "terotentikasi",
  lang41: "Pergi ke otentikasi",
  lang42: "Ulasan sertifikasi",
  lang43: "Otentikasi gagal",
  lang44: "Informasi identitas",
  lang45: "nama",
  lang46: "Silakan masukkan nama anda",
  lang47: "ID kartu",
  lang48: "Silakan masukkan nomor ID",
  lang49: "Unggah gambar ID (depan dan belakang)",
  lang50: "Otentikasi berhasil",
  lang51: "Tolong unggah gambar identitasnya",
  lang52: "Pusat keamanan",
  lang53: "Sandi log masuk",
  lang54: "Sandi pendanaan",
  lang55: "memodifikasi",
  lang56: "pengaturan",
  lang57: "Setelah mengubah password, penarikan dan fiat deposito dilarang dalam waktu 24 jam",
  lang58: "Sandi lam",
  lang59: "Tolong masukkan password lama",
  lang60: "Sandi baru",
  lang61: "Tolong masukkan password baru",
  lang62: "Konfirmasi sandi",
  lang63: "Silakan masukkan password konfirmasi",
  lang64: "Password harus 6-20 angka dan huruf",
  lang65: "Lupa passwordmu?",
  lang66: "Tolong isi password yang lama yang terdiri dari 6-20 digit dan huruf!",
  lang67: "Tolong isi password baru yang terdiri dari 6-20 digit dan huruf!",
  lang68: "Email",
  lang69: "SMS",
  lang70: "Silakan masukkan password dana",
  lang71: "Konfirmasi sandi danname",
  lang72: "Tolong isi sandi dana yang terdiri dari 6-20 digit dan huruf!",
  lang73: "mengikat",
  lang74: "Ubah nomor ponsel",
  lang75: "Ikat nomor ponsel",
  lang76: "Atur nomor telepon",
  lang77: "Silakan masukkan nomor ponsel anda ingin mengikat dan memverifikasi nomor tersebut",
  lang78: "Untuk melindungi keamanan rekening, anda perlu memverifikasi rekening anda",
  lang79: "Kirim kode verifikasi ke {tel} untuk verifikasi keamanan",
  lang80: "Verifikasi keamanan rekening",
  lang81: "berikutnya",
  lang82: "Ubah email",
  lang83: "Tautan email",
  lang84: "Atur email pengikat account",
  lang85: "Silakan masukkan alamat email yang ingin anda untuk mengikat dan memverifikasi alamat email",
  lang86: "Tim saya",
  lang87: "Jumlah anggota tim",
  lang88: "Jumlah yang diundang",
  lang89: "Senarai tim",
  lang90: "ID pengguna",
  lang91: "Waktu undanga",
  lang92: "waktu",
  lang93: "pendapatan",
  lang94: "Perincian pendapatan",
  lang95: "Undang teman-teman",
  lang96: "Merekomendasikan seorang teman untuk BlackRock",
  lang97: "Sampai 10% dari potongan biaya transaksi",
  lang98: "Undang teman-teman untuk mendaftar bersama",
  lang99: "Kirim undanga",
  lang100: "Untuk teman-teman",
  lang101: "Pendaftaran teman",
  lang102: "Buat transaksi",
  lang103: "Dapatkan sesuai",
  lang104: "Potongan proporsional",
  lang105: "Kodeku.",
  lang106: "Tautan saya",
  lang107: "Tampilkan Poster",
  lang108: "Aturan Reward",
  lang109: "1. Pengguna melengkapi pendaftaran dengan berbagi kode promosi atau tautan promosi. Setiap kali seorang teman menghasilkan transaksi mata uang yang nyata, transaksi ETF dan transaksi kontrak, si pemegang saham bisa mendapatkan proporsi yang sesuai dari hadiah biaya penanganan. Untuk 18 desember 2021 di masa depan, teman-teman yang mengundang teman-teman yang membuka rekening melalui sharer juga dapat menikmati pahala komisi 10% saja.",
  lang110: "2. Rasio imbalan: mata uang, ETF, nilai tukar kontrak: rasio imbalan terkait dengan nilai rata-rata saham CTT harian. Aturan yang spesifik adalah sebagai berikut:",
  lang111: "(1) Token CTT kurang dari 500, rasio pahala adalah 10%",
  lang112: "(2) tahan 500-5000 CTT Token, rasio pahala adalah 20%",
  lang113: "(3) jika anda memegang lebih dari 5.000 koin CTT, rasio hadiahnya adalah 30%",
  lang114: "(4) mitra BlackRock global, silakan hubungi staf platform untuk imbalan yang lebih tinggi.",
  lang115: "3. Bentuk imbalan: imbalan yang sebenarnya diperoleh dari pemiliknya akan dikembalikan dalam mata uang yang sebenarnya digunakan oleh sang teman untuk membayar biaya transennya. Misalnya: ketika teman menggunakan et untuk menyelesaikan biaya transaksi, imbalan nyata yang diperoleh oleh orang yang mengambil untung akan dikembalikan pada tahun et.. Ketika biaya transaksi teman diselesaikan dengan dett dikurangi, imbalan nyata yang diperoleh oleh orang yang mengambil bagian dan teman itu akan dikembalikan pada tahun CTT.",
  lang116: "Simpan gamba",
  lang117: "Simpan gambar itu dengan sukses",
  lang118: "Gagal menyelamatkan citra",
  lang119: "Pusat bantuanname",
  lang120: "Tim produk BlackRock",
  lang121: "Selamat datang untuk berkomunikasi dengan kami, setiap bagian umpan balik anda akan dihargai",
  lang122: "Detail masalah",
  lang123: "Kirim perintah kerja",
  lang124: "Judul kerja",
  lang125: "Silakan masukkan judul tiket",
  lang126: "Isi perintah kerja",
  lang127: "Tolong jelaskan masalah anda secara terperinci, itu akan membantu kami membantu anda dengan lebih efektif!",
  lang128: "Senarai perintah kerja",
  lang129: "Balasan:",
  lang130: "Proses, harap bersabar …",
  lang131: "Belum ada perintah kerja.",
  lang132: "menunggu",
  lang133: "jawab",
  lang134: "ditutup",
  lang135: "Ukuran gambar tidak dapat melebihi 5MB",
  lang136: "Silakan masukkan isi dari perintah kerja",
  lang137: "Jumlah kata dalam urutan kerja adalah antara 10-200",
  lang138: "Tentang kita",
  lang139: "Platform perdagangan BlackRock",
  lang140: "Telepon layanan pelanggan",
  lang141: "Twitter resmi",
  lang142: "Telegram resmi",
  lang143: "Kotak surat perusahaan",
  lang144: "pengaturan",
  lang145: "bahas",
  lang146: "versi",
  lang147: "Log out",
  lang148: "Kau yakin mau keluar?",
  lang149: "Memimpin dunia",
  lang150: "Platform perdagangan aset Digital",
  lang151: "Selamat datang di download BlackRock",
  lang152: "Register",
  lang153: "Pertukaran kripto multifungsional dan demokratis. Sebagai yang pertama di pasar, kami memberikan keuntungan finansial kepada pengguna aktif. BlackRock adalah proyek holistik, yang berarti mengimplementasikan solusi pada beberapa lapisan bisnis mengenai solusi mata uang kripto untuk mempermudah penggunaan pengguna kita.",
  lang154: "Visi kita",
  lang155: "Kami yakin penerapan luas teknologi blockchain dan keuntungan mata uang kripto dalam waktu dekat. Dengan memperkenalkan solusi global tidak hanya karena inovasi teknologi tetapi juga berkat pendidikan, ekosistem dunia kripto menjadi lebih ramah bagi semua orang.",
  lang156: "Aplikasi unduhan",
  lang157: "Kertas putih",
  lang158: "kredit"
};