export default {
  lang1: 'Đăng nhập',
  lang2: 'Bạn cũng có thể sử dụng',
  lang3: 'Email',
  lang4: 'Điện thoại di động',
  lang5: 'Điện thoại',
  lang6: 'Vui lòng nhập số điện thoại di động của bạn',
  lang7: 'Email',
  lang8: 'Vui lòng nhập tài khoản email của bạn',
  lang9: 'Mật khẩu đăng nhập',
  lang9a: 'Vui lòng nhập mật khẩu đăng nhập',
  lang10: 'Quên mật khẩu',
  lang11: 'Đăng nhập',
  lang12: 'Tìm kiếm quốc gia/khu vực',
  lang13: 'Khu vực thường dùng',
  lang14: 'Email',
  lang15: 'Điện thoại di động',
  lang16: 'Đặt mật khẩu đăng nhập',
  lang17: '6-20 ký tự, bao gồm chữ cái và số',
  lang18: 'Xác nhận mật khẩu đăng nhập',
  lang19: 'Vui lòng nhập lại mật khẩu',
  lang20: 'Mã',
  lang21: 'Vui lòng nhập mã xác minh',
  lang22: 'Gửi',
  lang23: 'Mã mời (tùy chọn)',
  lang23a: 'Vui lòng nhập mã mời',
  lang24: 'Tôi đã đọc và đồng ý',
  lang25: 'Thỏa thuận người dùng',
  lang26: 'Chính sách bảo mật',
  lang27: 'Vui lòng điền đúng địa chỉ email',
  lang28: 'Vui lòng điền mật khẩu đăng nhập gồm 6-20 chữ số và chữ cái',
  lang29: 'Hai mật khẩu nhập không nhất quán',
  lang30: 'Tải xuống Android',
  lang31: 'Tải xuống iOS',
  lang32: 'Sau khi lấy lại mật khẩu, tiền tệ không thể được rút trong vòng 24 giờ',
  lang33: 'Đặt lại mật khẩu',
  lang34: 'Email',
  lang35: 'Điện thoại di động',
  lang36: 'Nhấp để đăng nhập',
  lang37: 'Chào mừng đến với BlackRock',
  lang38: 'Chào mừng',
  lang39: 'KYC',
  lang40: 'Đã xác thực',
  lang41: 'Đi đến xác thực',
  lang42: 'Xem xét chứng nhận',
  lang43: 'Xác thực không thành công',
  lang44: 'Thông tin nhận dạng',
  lang45: 'Tên',
  lang46: 'Vui lòng nhập tên của bạn',
  lang47: 'Thẻ căn cước',
  lang48: 'Vui lòng nhập số CMND',
  lang49: 'Tải ảnh CMND lên (mặt trước và mặt sau)',
  lang50: 'Xác thực thành công',
  lang51: 'Vui lòng tải ảnh CMND lên',
  lang52: 'Trung tâm bảo mật',
  lang53: 'mật khẩu đăng nhập',
  lang54: 'Mật khẩu nạp tiền',
  lang55: 'Sửa đổi',
  lang56: 'Cài đặt',
  lang57: 'Sau khi thay đổi mật khẩu, việc rút tiền và gửi tiền fiat sẽ bị cấm trong vòng 24 giờ',
  lang58: 'Mật khẩu cũ',
  lang59: 'Vui lòng nhập mật khẩu cũ',
  lang60: 'Mật khẩu mới',
  lang61: 'Vui lòng nhập mật khẩu mới',
  lang62: 'Xác nhận mật khẩu',
  lang63: 'Vui lòng nhập mật khẩu xác nhận',
  lang64: 'Mật khẩu phải gồm 6-20 chữ số và chữ cái',
  lang65: 'Quên mật khẩu?',
  lang66: 'Vui lòng điền mật khẩu cũ gồm 6-20 chữ số và chữ cái!',
  lang67: 'Vui lòng điền mật khẩu mới gồm 6-20 chữ số và chữ cái!',
  lang68: 'Email',
  lang69: 'SMS',
  lang70: 'Vui lòng nhập mật khẩu quỹ',
  lang71: 'Xác nhận mật khẩu quỹ',
  lang72: 'Vui lòng điền mật khẩu quỹ gồm 6-20 chữ số và chữ cái!',
  lang73: 'Liên kết',
  lang74: 'Thay đổi số điện thoại di động',
  lang75: 'Liên kết số điện thoại di động',
  lang76: 'Đặt số điện thoại',
  lang77: 'Vui lòng nhập số điện thoại di động bạn muốn liên kết và xác minh số',
  lang78: 'Để bảo vệ tính bảo mật của tài khoản, bạn cần xác minh tài khoản của mình',
  lang79: 'Gửi mã xác minh đến {tel} để xác minh tính bảo mật',
  lang80: 'Xác minh tính bảo mật của tài khoản',
  lang81: 'Tiếp theo',
  lang82: 'Thay đổi email',
  lang83: 'Liên kết email',
  lang84: 'Đặt email liên kết tài khoản',
  lang85: 'Vui lòng nhập địa chỉ email bạn muốn liên kết và xác minh địa chỉ email',
  lang86: 'Nhóm của tôi',
  lang87: 'Tổng số thành viên trong nhóm',
  lang88: 'Số lượng người được mời',
  lang89: 'Danh sách nhóm',
  lang90: 'ID người dùng',
  lang91: 'Thời gian mời',
  lang92: 'thời gian',
  lang93: 'Doanh thu',
  lang94: 'Chi tiết doanh thu',
  lang95: 'Mời bạn bè',
  lang96: 'Giới thiệu bạn bè đến BlackRock',
  lang97: 'Hoàn tiền lên đến 10% phí giao dịch',
  lang98: 'Mời bạn bè đăng ký cùng nhau',
  lang99: 'Gửi lời mời',
  lang100: 'Dành cho bạn bè',
  lang101: 'Đăng ký bạn bè',
  lang102: 'Thực hiện giao dịch',
  lang103: 'Nhận tương ứng',
  lang104: 'Hoàn tiền theo tỷ lệ',
  lang105: 'Mã của tôi',
  lang106: 'Liên kết của tôi',
  lang107: 'Xem Người đăng',
  lang108: 'Quy tắc thưởng',
  lang109: '1. Người dùng hoàn tất đăng ký bằng cách chia sẻ mã khuyến mại hoặc liên kết khuyến mại. Mỗi khi bạn bè tạo ra giao dịch tiền tệ thực, giao dịch ETF và giao dịch hợp đồng, người chia sẻ có thể nhận được phần thưởng phí xử lý tương ứng. Ngày 18 tháng 12 năm 2021 Trong tương lai, những người bạn mời bạn bè mở tài khoản thông qua người chia sẻ cũng có thể được hưởng phần thưởng hoa hồng 10% một mình.',
  lang110: "2. Tỷ lệ phần thưởng: tiền tệ, ETF, tỷ lệ phần thưởng giao dịch hợp đồng: Tỷ lệ phần thưởng liên quan đến lượng nắm giữ CTT Token trung bình/hàng ngày của người chia sẻ. Các quy tắc cụ thể như sau:",
  lang111: '(1) CTT Token nhỏ hơn 500, tỷ lệ phần thưởng là 10%',
  lang112: '(2) Nắm giữ 500-5000 CTT Token, tỷ lệ phần thưởng là 20%',
  lang113: '(3) Nếu bạn nắm giữ hơn 5.000 CTT Token, tỷ lệ phần thưởng là 30%',
  lang114: '(4) Đối tác toàn cầu của BlackRock, vui lòng liên hệ với nhân viên nền tảng để nhận phần thưởng cao hơn.',
  lang115: "3. Biểu mẫu phần thưởng: Phần thưởng thực tế mà người chia sẻ nhận được sẽ được trả lại bằng loại tiền tệ thực tế mà bạn bè sử dụng để thanh toán phí giao dịch. Ví dụ: khi bạn bè sử dụng ETH để thanh toán phí giao dịch, phần thưởng thực tế mà người chia sẻ nhận được sẽ được trả lại bằng ETH. .Khi phí giao dịch của bạn bè được thanh toán bằng cách khấu trừ CTT, phần thưởng thực tế mà người chia sẻ và bạn bè nhận được sẽ được trả lại bằng CTT.",
  lang116: 'Lưu ảnh',
  lang117: 'Lưu ảnh thành công',
  lang118: 'Không lưu được ảnh',
  lang119: 'Trung tâm trợ giúp',
  lang120: 'Nhóm sản phẩm BlackRock',
  lang121: 'Chào mừng bạn đến giao tiếp với chúng tôi, mọi phản hồi của bạn đều được trân trọng',
  lang122: 'Chi tiết sự cố',
  lang123: 'Gửi lệnh làm việc',
  lang124: 'Tiêu đề lệnh làm việc',
  lang125: 'Vui lòng nhập tiêu đề của phiếu',
  lang126: 'Nội dung lệnh làm việc',
  lang127: 'Vui lòng mô tả chi tiết vấn đề của bạn, điều này sẽ giúp chúng tôi giúp bạn hiệu quả hơn!',
  lang128: 'Danh sách lệnh làm việc',
  lang129: 'Trả lời:',
  lang130: 'Đang xử lý, vui lòng kiên nhẫn...',
  lang131: 'Chưa có lệnh làm việc nào',
  lang132: 'đang chờ',
  lang133: 'Đã phản hồi',
  lang134: 'đã đóng',
  lang135: 'Kích thước hình ảnh không được vượt quá 5MB',
  lang136: 'Vui lòng nhập nội dung lệnh làm việc',
  lang137: 'Số lượng từ trong lệnh làm việc nằm trong khoảng từ 10-200',
  lang138: 'Giới thiệu về chúng tôi',
  lang139: 'Nền tảng giao dịch BlackRock',
  lang140: 'Số điện thoại dịch vụ khách hàng',
  lang141: 'Twitter chính thức',
  lang142: 'Telegram chính thức',
  lang143: 'Hộp thư công ty',
  lang144: 'Cài đặt',
  lang145: 'Ngôn ngữ',
  lang146: 'Phiên bản',
  lang147: 'Đăng xuất',
  lang148: 'Bạn có chắc chắn muốn đăng xuất không?',
  lang149: "Nền tảng giao dịch tài sản kỹ thuật số hàng đầu thế giới",
  lang150: 'Nền tảng giao dịch tài sản kỹ thuật số',
  lang151: 'Chào mừng bạn tải xuống BlackRock',
  lang152: 'Đăng ký',
  lang153: 'Sàn giao dịch tiền điện tử đa chức năng và dân chủ. Là sàn giao dịch đầu tiên trên thị trường, chúng tôi mang lại lợi ích tài chính cho người dùng tích cực. BlackRock là một dự án toàn diện, nghĩa là triển khai các giải pháp trên một vài lớp kinh doanh liên quan đến các giải pháp tiền điện tử để giúp người dùng của chúng tôi sử dụng dễ dàng hơn.',
  lang154: 'Tầm nhìn của chúng tôi',
  lang155: 'Chúng tôi chắc chắn về ứng dụng rộng rãi của công nghệ blockchain và lợi ích của tiền điện tử trong tương lai gần. Bằng cách giới thiệu các giải pháp toàn cầu không chỉ nhờ vào các cải tiến công nghệ mà còn nhờ vào giáo dục, hệ sinh thái thế giới tiền điện tử trở nên thân thiện hơn với mọi người.',
  lang156: 'Tải xuống ỨNG DỤNG',
  lang157: 'Sách trắng',
  lang158: 'tín dụng',
  lang159: 'Tài sản nền tảng',
  lang160: 'Tài sản quản lý tài chính',
  lang161: 'Tài sản hợp đồng',
  lang162: 'Số dư tài khoản',
  lang163: 'Nạp tiền',
  lang164: 'Rút tiền',
  lang165: 'Chuyển khoản',
  lang166: 'Chi tiết tài sản',
  lang167: 'Chào mừng đăng nhập',
  lang168: 'Tài khoản',
  lang169: 'Chưa có tài khoản?',
  lang170: 'Chào mừng đăng ký',
  lang171: 'Đã có tài khoản?',
  lang172: 'Đăng nhập ngay',
  lang173: 'Bằng cách đăng ký, tôi đồng ý với xx',
  lang174: 'Điều khoản sử dụng',
  lang175: 'và',
  lang176: 'Chính sách bảo mật'
};