import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { rechargeChannel } from '@api/user';
export default {
  data: function data() {
    return {
      searchVal: '',
      hideChecked: false
    };
  },
  computed: _objectSpread({}, mapState({
    exchangeAmount: function exchangeAmount(state) {
      return state.wallet.exchangeAmount;
    },
    exchangeCoinList: function exchangeCoinList(state) {
      return state.wallet.exchangeCoinList;
    },
    rateData: function rateData(state) {
      return state.wallet.rateData;
    }
  }), {
    filteredExchangeCoinList: function filteredExchangeCoinList() {
      var _this = this;

      return this.exchangeCoinList.filter(function (item) {
        var matchesSearch = item.coin.indexOf(_this.searchVal.toLocaleUpperCase()) !== -1;
        var matchesHideChecked = !_this.hideChecked || _this.hideChecked && item.usable != 0;
        return matchesSearch && matchesHideChecked;
      });
    }
  }),
  created: function created() {
    this.$store.dispatch('wallet/getExchangeAssets');
  },
  methods: {
    showRecord: function showRecord(data) {
      sessionStorage.setItem('balancesData', JSON.stringify(data));
      this.pushPath('/balanceRecord');
    },
    rechargeChannelInfo: function rechargeChannelInfo() {
      var _this2 = this;

      rechargeChannel().then(function (res) {
        if (res.data == 'SM') {
          _this2.pushPath('/coinRecharge');
        } else {
          _Dialog.alert({
            //title: '标题',
            message: _this2.$t('commonLang.lang16'),
            confirmButtonText: _this2.$t('commonLang.lang9')
          }).then(function () {
            var _window$Tawk_API;

            (_window$Tawk_API = window.Tawk_API) === null || _window$Tawk_API === void 0 ? void 0 : _window$Tawk_API.maximize();
          });
        }
      });
    }
  }
};