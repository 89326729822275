import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { tradeSymbols } from '@api/exchange';
import { futuresSymbols } from '@api/futures';
import { cycleSymbols } from '@api/cycle';
export default {
  data: function data() {
    return {
      active: sessionStorage.getItem('marketActive') || 3,
      mainNav: sessionStorage.getItem('marketMian') || 2,
      volSort: 'no',
      priceSort: 'no',
      riseSort: 'no',
      optionalData: JSON.parse(localStorage.getItem('optionalData')) || [],
      hasExchangeOptional: false,
      hasExchangePioneer: false,
      exchangeList: [],
      optionalFuturesData: JSON.parse(localStorage.getItem('optionalFuturesData')) || [],
      hasFuturesOptional: false,
      hasFuturesPioneer: false,
      futuresList: [],
      optionalCycleData: JSON.parse(localStorage.getItem('optionalCycleData')) || [],
      hasCycleOptional: false,
      hasCyclePioneer: false,
      cycleList: [],
      searchVal: ''
    };
  },
  computed: _objectSpread({}, mapState({
    marketSocketData: function marketSocketData(state) {
      return state.trade.marketSocketData;
    },
    rateData: function rateData(state) {
      return state.wallet.rateData;
    }
  })),
  watch: {
    // 监听socket 数据  实时更新
    marketSocketData: function marketSocketData() {
      this.initMarket();
    },
    active: function active(val) {
      sessionStorage.setItem('marketActive', val);
      this.mainNav = 2;
      this.volSort = 'no';
      this.priceSort = 'no';
      this.riseSort = 'no';
    },
    mainNav: function mainNav(val) {
      sessionStorage.setItem('marketMian', val);
    },
    searchVal: {
      handler: function handler() {
        this.searchKey();
      }
    }
  },
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 1);
    this.initMarket();
  },
  mounted: function mounted() {
    var marketTop = this.$refs.marketTop;
    marketTop.style.paddingTop = "".concat(this.$barHeight, "px");
    this.$refs.marketBox.style.top = "".concat(marketTop.clientHeight, "px");
  },
  methods: {
    initMarket: function initMarket() {
      if (this.marketSocketData && this.marketSocketData.symbols) {
        // 更新行情
        if (this.marketSocketData.symbols) {
          this.processExchangeData(this.marketSocketData.symbols);
          sessionStorage.setItem('marketTradeList', JSON.stringify(this.marketSocketData.symbols));
        } // 更新行情


        if (this.marketSocketData.consymbols) {
          this.processFuturesData(this.marketSocketData.consymbols);
          sessionStorage.setItem('marketFuturesList', JSON.stringify(this.marketSocketData.consymbols));
        } // 更新行情


        if (this.marketSocketData.cyclesymbols) {
          this.processCycleData(this.marketSocketData.cyclesymbols);
          sessionStorage.setItem('marketCycleList', JSON.stringify(this.marketSocketData.cyclesymbols));
        }
      } else {
        this.getSymbols();
      }
    },
    getSymbols: function getSymbols() {
      var _this = this;

      tradeSymbols().then(function (res) {
        _this.processExchangeData(res.data);

        sessionStorage.setItem('marketTradeList', JSON.stringify(res.data));
      });
      futuresSymbols().then(function (res) {
        _this.processFuturesData(res.data);

        sessionStorage.setItem('marketFuturesList', JSON.stringify(res.data));
      });
      cycleSymbols().then(function (res) {
        _this.processCycleData(res.data);

        sessionStorage.setItem('marketCycleList', JSON.stringify(res.data));
      });
    },
    processExchangeData: function processExchangeData(resData) {
      var _this2 = this;

      // 自选列表
      resData.forEach(function (data) {
        _this2.optionalData.forEach(function (id) {
          if (id == data.autoid) {
            _this2.hasExchangeOptional = true;

            _this2.$set(data, 'isOptional', true);
          }
        });

        if (data.plate == 'pioneer') {
          _this2.hasExchangePioneer = true;
        }
      });
      this.exchangeList = _toConsumableArray(this.sortData(resData));
    },
    processFuturesData: function processFuturesData(resData) {
      var _this3 = this;

      // 自选列表
      resData.forEach(function (data) {
        _this3.optionalFuturesData.forEach(function (id) {
          if (id == data.autoid) {
            _this3.hasFuturesOptional = true;

            _this3.$set(data, 'isOptional', true);
          }
        });

        if (data.plate == 'pioneer') {
          _this3.hasFuturesPioneer = true;
        }
      });
      this.futuresList = _toConsumableArray(this.sortData(resData));
    },
    processCycleData: function processCycleData(resData) {
      var _this4 = this;

      // 自选列表
      resData.forEach(function (data) {
        _this4.optionalCycleData.forEach(function (id) {
          if (id == data.autoid) {
            _this4.hasCycleOptional = true;

            _this4.$set(data, 'isOptional', true);
          }
        });

        if (data.plate == 'pioneer') {
          _this4.hasCyclePioneer = true;
        }
      });
      this.cycleList = _toConsumableArray(this.sortData(resData));
    },
    sortData: function sortData(resData) {
      if (this.volSort == 'down') {
        resData.sort(this.$utils.compareRise('volume'));
      } else if (this.volSort == 'up') {
        resData.sort(this.$utils.compareRise('volume', 'up'));
      } else if (this.priceSort == 'down') {
        resData.sort(this.$utils.compareRise('price'));
      } else if (this.priceSort == 'up') {
        resData.sort(this.$utils.compareRise('price', 'up'));
      } else if (this.riseSort == 'down') {
        resData.sort(this.$utils.compareRise('rise'));
      } else if (this.riseSort == 'up') {
        resData.sort(this.$utils.compareRise('rise', 'up'));
      }

      return resData;
    },
    changeSort: function changeSort(type) {
      if (type == 1) {
        this.volSort = this.volSort == 'down' ? 'up' : this.volSort == 'up' ? 'no' : 'down';
        this.priceSort = 'no';
        this.riseSort = 'no';
      }

      if (type == 2) {
        this.priceSort = this.priceSort == 'down' ? 'up' : this.priceSort == 'up' ? 'no' : 'down';
        this.volSort = 'no';
        this.riseSort = 'no';
      }

      if (type == 3) {
        this.riseSort = this.riseSort == 'down' ? 'up' : this.riseSort == 'up' ? 'no' : 'down';
        this.volSort = 'no';
        this.priceSort = 'no';
      }

      if (this.active == 1) {
        this.processExchangeData(this.exchangeList);
      } else if (this.active == 2) {
        this.processFuturesData(this.futuresList);
      } else if (this.active == 3) {
        this.processCycleData(this.cycleList);
      }
    },
    goDetails: function goDetails(data) {
      console.log('周期', data, this.active);

      if (this.active == 1) {
        // 以下是跳转货币的详情页的
        // sessionStorage.setItem('marketDetails', JSON.stringify(data))
        // this.pushPath('/marketDetails/trad')
        sessionStorage.setItem('selectedCoin', JSON.stringify(data));
        sessionStorage.setItem('tradeActive', 1);
        sessionStorage.setItem('tradeType', 0);
        this.$router.replace('/trade');
      } else if (this.active == 2) {
        sessionStorage.setItem('marketDetails', JSON.stringify(data));
        this.pushPath('/marketDetails/contract');
      } else {
        sessionStorage.setItem('cycleCoinParams', JSON.stringify(data)); // this.pushPath('/cycle')

        sessionStorage.setItem('tradeActive', 2);
        this.$router.replace('/trade');
      }
    },
    searchKey: function searchKey() {
      var _this5 = this;

      console.log("searchVal: ".concat(this.searchVal)); // 根据当前active类型选择对应的列表数据

      if (this.active == 1) {
        var marketTradeList = sessionStorage.getItem('marketTradeList') || [];

        if (marketTradeList) {
          var searchList = this.coinSearchFilter(JSON.parse(marketTradeList));
          this.processExchangeData(searchList);
        } else {
          tradeSymbols().then(function (res) {
            sessionStorage.setItem('marketTradeList', JSON.stringify(res.data));

            var searchList = _this5.coinSearchFilter(res.data);

            _this5.processExchangeData(searchList);
          });
        }
      } else if (this.active == 2) {
        var marketFuturesList = sessionStorage.getItem('marketFuturesList') || [];

        if (marketFuturesList) {
          var _searchList = this.coinSearchFilter(JSON.parse(marketFuturesList));

          this.processFuturesData(_searchList);
        } else {
          futuresSymbols().then(function (res) {
            sessionStorage.setItem('marketFuturesList', JSON.stringify(res.data));

            var searchList = _this5.coinSearchFilter(res.data);

            _this5.processFuturesData(searchList);
          });
        }
      } else if (this.active == 3) {
        var marketCycleList = sessionStorage.getItem('marketCycleList') || [];

        if (marketCycleList) {
          var _searchList2 = this.coinSearchFilter(JSON.parse(marketCycleList));

          this.processCycleData(_searchList2);
        } else {
          cycleSymbols().then(function (res) {
            sessionStorage.setItem('marketCycleList', JSON.stringify(res.data));

            var searchList = _this5.coinSearchFilter(res.data);

            _this5.processCycleData(searchList);
          });
        }
      }
    },
    coinSearchFilter: function coinSearchFilter(targetList) {
      var _this6 = this;

      // 过滤符合搜索条件的数据
      return targetList.filter(function (item) {
        var searchTerm = _this6.searchVal.toLowerCase();

        var tradcoin = (item.tradcoin || '').toLowerCase();
        var maincoin = (item.maincoin || '').toLowerCase();
        return tradcoin.includes(searchTerm) || maincoin.includes(searchTerm);
      });
    }
  }
};