import { render, staticRenderFns } from "./futuresList.vue?vue&type=template&id=39f6dc01&scoped=true&"
import script from "./futuresList.vue?vue&type=script&lang=js&"
export * from "./futuresList.vue?vue&type=script&lang=js&"
import style0 from "./futuresList.vue?vue&type=style&index=0&id=39f6dc01&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39f6dc01",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mumulei/ideaProject/datagopro/bc.exchange/01_Code/H5/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39f6dc01')) {
      api.createRecord('39f6dc01', component.options)
    } else {
      api.reload('39f6dc01', component.options)
    }
    module.hot.accept("./futuresList.vue?vue&type=template&id=39f6dc01&scoped=true&", function () {
      api.rerender('39f6dc01', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/wallet/components/futuresList.vue"
export default component.exports