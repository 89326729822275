import { post, fetch } from '@/service';
import store from '@/store';
var baseURL = '/api/';

var showLoading = function showLoading() {
  store.dispatch('common/showLoading', true);
};

export var futuresOverview = function futuresOverview() {
  return fetch("".concat(baseURL, "/contract/overview"));
};
export var futuresSymbols = function futuresSymbols() {
  return fetch("".concat(baseURL, "/contract/symbols"));
};
export var contractCoinInfo = function contractCoinInfo(data) {
  return fetch("".concat(baseURL, "/contract/contract"), data);
};
export var contractLever = function contractLever(data) {
  return fetch("".concat(baseURL, "/contract/lever"), data);
};
export var contractAccount = function contractAccount(data) {
  return fetch("".concat(baseURL, "/contract/contractAccount"), data);
};
export var buyLimit = function buyLimit(data) {
  showLoading();
  return post("".concat(baseURL, "/contract/buyLimit"), data);
};
export var sellLimit = function sellLimit(data) {
  showLoading();
  return post("".concat(baseURL, "/contract/sellLimit"), data);
};
export var buyMarket = function buyMarket(data) {
  showLoading();
  return post("".concat(baseURL, "/contract/buyMarket"), data);
};
export var sellMarket = function sellMarket(data) {
  showLoading();
  return post("".concat(baseURL, "/contract/sellMarket"), data);
};
export var closeEntrust = function closeEntrust(data) {
  showLoading();
  return post("".concat(baseURL, "/contract/closeEntrust"), data);
};
export var positionOrderByCoin = function positionOrderByCoin(data) {
  return fetch("".concat(baseURL, "/contract/positionByCoin"), data);
};
export var closePosition = function closePosition(data) {
  showLoading();
  return post("".concat(baseURL, "/contract/closePosition"), data);
};
export var closeCoinPosition = function closeCoinPosition(data) {
  showLoading();
  return post("".concat(baseURL, "/contract/closeCoinPosition"), data);
};
export var closeAllPosition = function closeAllPosition(data) {
  showLoading();
  return post("".concat(baseURL, "/contract/closeAllPosition"), data);
};
export var allPosition = function allPosition(data) {
  return fetch("".concat(baseURL, "/contract/position"), data);
};
export var historyPosition = function historyPosition(data) {
  return fetch("".concat(baseURL, "/contract/tradOrder"), data);
};
export var tradEntrust = function tradEntrust(data) {
  return fetch("".concat(baseURL, "/contract/tradEntrust"), data);
};
export var tradHistory = function tradHistory(data) {
  return fetch("".concat(baseURL, "/contract/tradHistory"), data);
};
export var profitLossOrder = function profitLossOrder(data) {
  return fetch("".concat(baseURL, "/contract/profitLossOrder"), data);
};
export var closeProfitLoss = function closeProfitLoss(data) {
  showLoading();
  return post("".concat(baseURL, "/contract/closeProfitLoss"), data);
};
export var editProfitLoss = function editProfitLoss(data) {
  showLoading();
  return post("".concat(baseURL, "/contract/editProfitLoss"), data);
};
export var profitLoss = function profitLoss(data) {
  showLoading();
  return post("".concat(baseURL, "/contract/profitLoss"), data);
};
export var contractKline = function contractKline(data) {
  return fetch("".concat(baseURL, "/contract/kline"), data);
};
export var groupIncome = function groupIncome() {
  return fetch("".concat(baseURL, "/contract/groupIncome"));
};
export var contractIncome = function contractIncome(data) {
  return fetch("".concat(baseURL, "/contract/contractIncome"), data);
};