export default {
  "lang1": "determinar",
  "lang2": "cancelar",
  "lang3": "Você deseja baixar um novo pacote de instalação?",
  "lang4": "A transferir",
  "lang5": "Não foi possível transferir o pacote de actualização",
  "lang6": "Por favor reabra a aplicação após a instalação!",
  "lang7": "Não foi possível actualizar os recursos",
  "lang8": "Não há serviço de rede no momento, verifique a conexão de rede!",
  "lang9": "Carregue novamente para sair do programa",
  "lang10": "Actualização da versão do APP",
  "lang11": "1. Otimização da experiência de uso; 2. Corrigimos alguns problemas conhecidos.",
  "lang12": "Actualizar Agora",
  "lang13": "Não foi possível transferir o pacote de instalação!",
  "lang14": "",
  "lang15": "",
  "lang16": "",
  "lang17": "",
  "lang18": "",
  "lang19": ""
};