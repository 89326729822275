var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "trade" }, [
    _c("div", { ref: "tradeTop", staticClass: "trade_top" }, [
      _c("ol", { staticClass: "trade_nav flex" }, [
        _c(
          "p",
          {
            staticClass: "back-btn",
            on: {
              click: function($event) {
                return _vm.goBack()
              }
            }
          },
          [
            _c("img", {
              attrs: {
                width: "15",
                height: "15",
                src: require("@img/common/go_back.png"),
                alt: ""
              }
            })
          ]
        ),
        _c(
          "li",
          {
            class: [{ nav_active: _vm.active == 2 }],
            on: {
              click: function($event) {
                _vm.active = 2
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("addLang.lang37")))]
        ),
        _c(
          "li",
          {
            class: [{ nav_active: _vm.active == 1 }],
            on: {
              click: function($event) {
                _vm.active = 1
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("addLang.lang38")))]
        )
      ])
    ]),
    _c(
      "div",
      { ref: "tradeBox" },
      [
        _vm.active == 1 ? _c("coinExchange") : _vm._e(),
        _vm.active == 2 ? _c("cycle") : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }