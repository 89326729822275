export default {
  lang1: 'Home',
  lang2: 'IEO new currency subscription',
  lang3: 'Collection tokens will be released daily',
  lang4: 'Covert',
  lang5: 'Not open',
  lang6: 'Ended',
  lang7: 'Price',
  lang8: 'Start time',
  lang9: 'Total Issued',
  lang10: 'End time',
  lang11: 'Surplus amount',
  lang12: 'Locking Period',
  lang13: 'D',
  lang14: 'Buy {coin}',
  lang15: 'Payment coin',
  lang15a: 'Pay',
  lang16: 'balance',
  lang17: 'Use all',
  lang18: 'Please enter the purchase quantity',
  lang19: 'Pay:',
  lang20: 'Gift:',
  lang21: 'Order record',
  lang22: 'Release',
  lang23: 'IEO pledges',
  lang24: 'Rebate',
  lang25: 'Please enter the correct purchase quantity',
  lang26: 'The remaining total is insufficient',
  lang27: 'Are you sure to exchange {number}{coin}?',
  lang28: 'Notice',
  lang29: 'No notice yet',
  lang30: 'Notice Details',
  lang31: 'Enter Token to search',
  lang32: 'Mining every day',
  lang33: 'The higher the force, the more mining',
  lang34: 'Deal',
  lang35: 'Rising',
  lang36: 'Decline',
  lang37: 'Actuals',
  lang38: 'Futures',
  lang39: 'Cycle',
  lang40: 'Favorites',
  lang41: 'All',
  lang42: 'Mian',
  lang43: 'Next',
  lang44: 'Vol',
  lang45: 'Price',
  lang46: 'Change%',
  lang47: 'Locked Mining',
  lang48: 'Managed funds',
  lang49: 'Earnings today',
  lang50: 'Cumulative income',
  lang51: 'Managed order',
  lang52: 'Daily Yield',
  lang53: 'day',
  lang54: 'Confirm order',
  lang55: 'Profit',
  lang56: 'Cycle',
  lang57: 'Single Limit',
  lang58: 'Dividend time',
  lang59: 'Daily',
  lang60: 'Escrow Amount',
  lang61: 'Return when expired',
  lang62: 'Early Redemption',
  lang63: 'Please enter the escrow amount',
  lang64: 'Estimated revenue',
  lang65: 'Please enter the correct escrow amount',
  lang66: 'Insufficient account balance',
  lang67: 'The amount is not within the single limit',
  lang68: 'Locking record',
  lang69: 'Running',
  lang70: 'Complete',
  lang71: 'Redeemed',
  lang72: 'Cumulative income',
  lang73: 'Management time',
  lang74: 'Expiration time',
  lang75: 'Determine the early redemption of custody, the corresponding handling fee will be deducted for early redemption',
  lang76: 'Popular Coins',
  lang77: 'Foreign Currency',
  lang78: 'International'
};