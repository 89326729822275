import "core-js/modules/es.array.includes";
import "core-js/modules/es.function.name";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'NavFooter',
  computed: {
    selected: {
      get: function get() {
        return this.$store.state.common.selectedFooter;
      },
      set: function set(newValue) {
        this.$store.state.common.selectedFooter = newValue;
      }
    }
  },
  methods: {
    pushPage: function pushPage(path) {
      switch (path) {
        case 'home':
          this.selected = 0;
          break;

        case 'market':
          this.selected = 1;
          break;

        case 'trade':
          this.selected = 2;
          break;

        case 'balances':
          this.selected = 3;
          break;

        case 'personal':
          this.selected = 4;
          break;
      }

      this.$router.replace(path);
    }
  },
  watch: {
    $route: function $route(to, from) {
      var _this = this;

      if (['home', 'market', 'trade', 'balances', 'personal'].includes(to.name)) {
        this.$store.dispatch('common/showFooter', true);
      } else {
        setTimeout(function () {
          _this.$store.dispatch('common/showFooter', false);
        }, 10);
      }
    }
  }
};