import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import $ from 'jquery';
import { systemTime } from "@api/common";
export default {
  name: 'Drift',
  directives: {
    drag: function drag(el, bindings) {
      el.onmousedown = function (e) {
        var disx = e.pageX - el.offsetLeft;
        var disy = e.pageY - el.offsetTop;

        document.onmousemove = function (e) {
          el.style.left = e.pageX - disx + 'px';
          el.style.top = e.pageY - disx + 'px';
        };

        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };
    }
  },
  props: ['nowPrice', 'id', 'top', 'data', 'offsetTime'],
  data: function data() {
    return {
      isdrag: true,
      tempX: '',
      x: '',
      tempY: '',
      y: '',
      time: null
    };
  },
  watch: {},
  created: function created() {
    this.setCountDown();
  },
  methods: {
    setCountDown: function setCountDown() {
      this.time = Number(+this.data.wdate) + Number(+this.data.lever) * 1000 - Number(this.data.serverTime);
    },
    toTop: function toTop() {
      var top = document.documentElement.scrollTop || document.body.scrollTop; // 实现滚动效果

      var timeTop = setInterval(function () {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;

        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    // 触摸开始（手指放在触摸屏上）
    dragStart: function dragStart(e) {
      this.isdrag = true;
      this.tempX = parseInt($("#".concat(this.id)).css('left') + 0);
      this.tempY = parseInt($("#".concat(this.id)).css('top') + 0);
      this.x = e.touches[0].pageX;
      this.y = e.touches[0].pageY;
    },
    // 拖动（手指在触摸屏上移动）
    dragMove: function dragMove(e) {
      if (this.isdrag) {
        var curX = this.tempX + e.touches[0].pageX - this.x;
        var curY = this.tempY + e.touches[0].pageY - this.y; // 边界判断

        curX = curX < 0 ? 0 : curX;
        curY = curY < 0 ? 0 : curY;
        curX = curX < document.documentElement.clientWidth - $("#".concat(this.id))[0].clientWidth ? curX : document.documentElement.clientWidth - $("#".concat(this.id))[0].clientWidth;
        curY = curY < document.documentElement.clientHeight - $("#".concat(this.id))[0].clientHeight ? curY : document.documentElement.clientHeight - $("#".concat(this.id))[0].clientHeight;
        $("#".concat(this.id)).css({
          'left': curX,
          'top': curY
        }); // 禁止浏览器默认事件

        e.preventDefault();
      }
    },
    // 拖动结束
    dragEnd: function dragEnd() {
      this.isdrag = false;
    },
    refreshData: function refreshData(time) {
      var _this = this;

      if (time.seconds == 1) {
        setTimeout(function () {
          _this.$emit('refreshData');
        }, 1000);
      }
    },
    finish: function finish() {
      console.log('结束了');
      $("#".concat(this.id)).remove();
      this.$emit('dragNotice', this.data);
    }
  }
};