//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import secondOrder from '@/views/coinExchange/secondOrder.vue';
import spotOrder from '@/views/coinExchange/spotOrder.vue';
export default {
  components: {
    secondOrder: secondOrder,
    spotOrder: spotOrder
  },
  data: function data() {
    return {
      active: sessionStorage.getItem('marketActive') || 3
    };
  },
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 2);
  },
  mounted: function mounted() {},
  methods: {}
};